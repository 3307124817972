.article-container {
  display: flex;
  border: 1px solid #afafaf;
  padding: 10px;
  margin-bottom: 30px;
}

.side-section {
  width: 20%;
  border-right: 1px solid #afafaf;
  margin-right: 30px;
}

.content-section {
  flex: 1;
  padding: 20px 0;
  position: relative;
}

.img-container {
  padding: 30px;
}

.feature-container {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -5px;
  width: 50%;
  float: left;
  margin-top: 10px;
}

.product-feature {
  width: 50%;
  padding: 0 5px;
}

.right-side {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30%;
}

.selector.unit,
.selector.quantity {
  width: 50%;
  float: left;
  margin-bottom: 30px;
}

.availibility {
  text-align: center;
}

.percentage {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #6db6c0;
  color: #ffffff;
  padding: 10px;
}

.price {
  position: absolute;
  top: 60px;
  right: 0px;
}

.selector.quantity {
  .quantity-select input {
    width: 90px !important;
  }
}
.selector.unit {
  font-size: small !important;
}

.icons {
  float: right;
  position: relative;
  top: 12px;
}

.cart-button {
  float: right;
  min-width: 290px;
}

.heart-icon-container,
.icon-grid-mobile {
  font-size: 25px !important;
  cursor: pointer;
  margin-right: 10px;
}

.icon--big {
  font-size: 25px;
  margin-right: 10px;
}

.availibility::before {
  width: 10px;
  height: 10px;
  background-color: forestgreen;
  content: '';
  display: block;
  left: 70px;
  position: relative;
  bottom: -11px;
}

.article-container:hover .link-to-article {
  text-decoration: underline;
  color: $colorGlacier !important;
}

.btn.configuration {
  background-color: $colorCeruleanBlue;
  border: 1px solid $colorCeruleanBlue;
  text-align: center;
}

.btn.configuration:hover {
  background-color: #393939;
}

.mat-dialog-container {
  max-height: 80% !important;
  height: 80% !important;
}
