.search-result-products-item {
  transition: color $transitionDurationDefault ease;
  padding: 3px 0;

  &:not(:last-child) {
    border-bottom: 1px solid $colorSilverChalice;
  }

  .no-touchevents &:hover {
    color: $colorActiveState;
  }
}

.search-result-products-item-image {
  width: 90px;
  flex-grow: 0;
  flex-shrink: 0;

  &:not(:last-child) {
    margin-right: 15px;
  }

  @include mobileSuperSmallMax {
    display: none;
  }
}

.search-result-products-item-info,
.search-result-products-item-text {
  flex-grow: 1;
  flex-shrink: 1;
}

.search-result-products-item-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  margin-right: 15px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 45px;
  width: 45px;
  color: $colorText;
  transition: color $transitionDurationDefault ease, background-color $transitionDurationDefault ease;

  .touchevents &,
  .no-touchevents .search-result-products-item:hover & {
    color: $colorBrightText;
    background-color: $colorActiveState;
  }
}
