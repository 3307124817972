.layout-outset-image {
  overflow: hidden;
  margin-bottom: 100px;

  &--remove-margin-bottom {
    margin-bottom: 0;
  }
}

.layout-outset-image-inner {
  display: flex;

  @include tabletMax {
    display: block;
  }
}

.layout-outset-image-main {
  flex: 1 1 auto;
  padding: 40px 0;

  p {
    margin-bottom: 20px;
  }
}

.layout-outset-image-header {
  flex: 0 0 auto;
  margin-right: $gutter;
  margin-left: calc((100vw - 100%) / -2);
  width: calc((100vw - 100%) / 2 + 66.66%);
  background-position: center center;
  background-size: cover;

  img {
    width: 100%;
    height: auto;
  }

  @include desktopMax {
    width: calc(#{$default-padding} + 50%);
  }

  @include tabletMax {
    margin-right: $default-padding * -1;
    width: auto;
  }

  @include mobileLargeMax {
    margin-right: $default-padding--mobile-large-max * -1;
    margin-left: $default-padding--mobile-large-max * -1;
  }

  @include mobileSmallMax {
    margin-right: $default-padding--mobile-small-max * -1;
    margin-left: $default-padding--mobile-small-max * -1;
  }
}
