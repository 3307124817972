.header-search {
  position: relative;
  height: 46px;
  background-color: $colorGallery;
  z-index: 3;

  form {
    height: 100%;
  }

  @include tabletMax {
    background-color: transparent;
  }
}

.header-search-input {
  max-width: calc(100% - 62px);
  background-color: transparent;
  height: 100%;
  line-height: 46px;
  padding: 0 20px;
  border: none;

  @include tabletMin {
    width: 320px !important; // Overwrite JS values.
  }

  @include desktopVerySmallMin {
    width: 470px !important; // Overwrite JS values.
  }

  @include tabletMax {
    position: absolute;
    top: 0;
    right: 100%;
    width: 0;
    padding: 14px 0;
    z-index: 1;
    background-color: $colorGallery;
    transition: padding $transitionDurationDefault ease, max-width $transitionDurationDefault ease;
    max-width: 0;

    .header-search.mobile-open & {
      padding: 14px;
      border-right: 2px solid $colorWhite;
      max-width: 100vw;
      width: 65vw !important;
    }
  }
}

input.header-search-input:focus {
  border: 1px solid $colorCeruleanBlue;
}

.header-search-submit {
  height: 100%;
  width: 62px;
  border-left: 2px solid $colorSilverChalice;
  font-size: 26px;
  color: $colorSilverChalice;

  @include tabletMax {
    border-left: none;
    color: $colorText;
    transition: color $transitionDurationDefault ease, background-color $transitionDurationDefault ease;

    .header-search.mobile-open & {
      color: $colorBrightText;
      background-color: $colorActiveState;
    }
  }
}
