.listing {
  position: relative;
  margin-top: 85px;

  &:not(:last-child) {
    margin-bottom: 60px;
  }

  @include tabletSmallMax {
    margin-top: 65px;
  }

  @include mobileLargeMax {
    margin-top: 45px;
  }

  @include mobileSmallMax {
    margin-top: 30px;
  }
}

.listing-content {
  width: calc(100% - 320px);

  @include tabletMax {
    width: 100%;
  }
}

.listing-info {
  position: relative;
  padding-bottom: 24px;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: rgba($colorBlack, 0.1);
  }

  @include tabletSmallMax {
    padding-bottom: 0;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &::after {
      display: none;
    }
  }
}

.listing-info-title {
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.listing-content-products-stats {
  display: flex;
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.listing-content-products-count {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @include tabletSmallMax {
    order: -1;
  }
}
