/* =================================================
        Sticky Footer.
================================================= */

body {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}
