@import '../../../components/product/listing-filters';

.listing-filters-categories-item {
  font-size: 16px;
}

.listing-filters-item-button {
  position: relative;
  width: 100%;
  padding: 7px 40px 6px 0;
  text-align: left;
  font-weight: 700;
  transition: color $transitionDurationDefault ease;

  &:hover,
  .selected &,
  .open &,
  .active & {
    color: $colorCeruleanBlue;

    @include tabletMax {
      color: $colorText;
    }
  }

  @include tabletMax {
    font-weight: 400;
  }
}
