/* =================================================
        Base Definitions.
================================================= */

@import 'base';

/* =================================================
        Includes. (Sections)
================================================= */

// CMS Blocks Styling.

// CMS Blocks for Company.
@import 'includes/cms/company/team';

// CMS Blocks for FAQ.
@import 'includes/cms/faq/faq-accordions';
@import 'includes/cms/faq/faq-intro';

// CMS Blocks for Popup.
@import 'includes/cms/popup/accept-cookie';
@import 'includes/cms/popup/cookie-bar';
@import 'includes/cms/popup/login';
@import 'includes/cms/popup/newsletter';

// CMS Blocks for Profile.
@import 'includes/cms/profile/carts';
@import 'includes/cms/profile/default-addresses';
@import 'includes/cms/profile/my-profile';
@import 'includes/cms/profile/password';
@import 'includes/cms/profile/profile-nav';
@import 'includes/cms/profile/profile-search';
@import 'includes/cms/profile/recorded-addresses';
@import 'includes/cms/profile/settings';
@import 'includes/cms/profile/user-administration-recorded-users';
@import 'includes/cms/profile/user-administration-recorded-users-table';
@import 'includes/cms/profile/watchlist-detail';
@import 'includes/cms/profile/watchlist-detail-cart-table';
@import 'includes/cms/profile/watchlists';

// CMS Blocks for Services.
@import 'includes/cms/services/brands';

// Other CMS Blocks.
@import 'includes/cms/accordion';
@import 'includes/cms/brands';
@import 'includes/cms/cart';
@import 'includes/cms/choose-location';
@import 'includes/cms/contact';
@import 'includes/cms/contact-disturber';
@import 'includes/cms/contact-form';
@import 'includes/cms/contact-intro';
@import 'includes/cms/divider';
@import 'includes/cms/fast-add-product';
@import 'includes/cms/filter-buttons';
@import 'includes/cms/hero-tiles';
@import 'includes/cms/inspiration-masonry';
@import 'includes/cms/intro';
@import 'includes/cms/last-seen';
@import 'includes/cms/listing';
@import 'includes/cms/login';
@import 'includes/cms/news';
//@import 'includes/cms/newsletter';
@import 'includes/cms/order-address';
@import 'includes/cms/order-check';
@import 'includes/cms/order-deliver-options';
@import 'includes/cms/order-meta';
@import 'includes/cms/order-payment';
@import 'includes/cms/order-progress';
@import 'includes/cms/order-sibling-nav';
@import 'includes/cms/popup';
@import 'includes/cms/portfolio';
@import 'includes/cms/post-slides';
@import 'includes/cms/seo';
@import 'includes/cms/similar-products';
//@import 'includes/cms/socials';
@import 'includes/cms/suggestions';
@import 'includes/cms/tabs';
@import 'includes/cms/teaser-slides';
@import 'includes/cms/text-block';
@import 'includes/cms/top-categories';
@import 'includes/cms/trends';
//@import 'includes/cms/videos';

// Footer Styling.
@import 'includes/footer/footer';

// Header Styling.
//@import 'includes/header/header';
//@import 'includes/header/header-detail';
@import 'includes/header/header-homepage';
@import 'includes/header/header-inspiration';

// @lobos/library
@import 'includes/library/scanner';

// Other Styles.
@import 'includes/breadcrumb';

/* =================================================
        Components.
================================================= */

// Buttons.
@import 'components/buttons/back-to-top';
@import 'components/buttons/btn-slim';
@import 'components/buttons/btn-wide';
@import 'components/buttons/pagination-infinity';

// FAQ.
@import 'components/faq/accordion-item';

// Footer Components.
@import 'components/footer/footer-info';
@import 'components/footer/footer-info-item';
@import 'components/footer/footer-info-items-full';
@import 'components/footer/footer-info-items-rounded';
@import 'components/footer/footer-info-items-small';
//@import 'components/footer/footer-main';
@import 'components/footer/footer-main-info';
@import 'components/footer/footer-main-nav';
//@import 'components/footer/footer-main-nav-side';
@import 'components/footer/footer-main-nav-side-support';
@import 'components/footer/footer-notes';

// Specialized Form Styling.
@import 'components/form/checkbox';
@import 'components/form/form-error';
@import 'components/form/input-default';
@import 'components/form/input-default-grey-large';
@import 'components/form/quantity-select';
@import 'components/form/range-slider';
@import 'components/form/select';
@import 'components/form/select-image';

// Header Components Styles.
//@import 'components/header/header-dropdown';
//@import 'components/header/header-info';
//@import 'components/header/header-main';
//@import 'components/header/header-nav';
@import 'components/header/header-user-info';
//@import 'components/header/header-search';
@import 'components/header/header-search-result';
@import 'components/header/header-search-result-category';
@import 'components/header/header-search-result-category-item';
@import 'components/header/header-search-result-products';
@import 'components/header/header-search-result-products-item';
@import 'components/header/navigation';
//@import 'components/header/navigation-item';
@import 'components/header/navigation-items';
//@import 'components/header/navigation-main-item';

// Login Style.
//@import 'components/login/login';
@import 'components/login/register';

// Order Style.
@import 'components/order/address';
@import 'components/order/check-aside';
@import 'components/order/check-products';
@import 'components/order/delivery-conditions';
@import 'components/order/delivery-date';
@import 'components/order/delivery-message';
@import 'components/order/delivery-options';
@import 'components/order/delivery-shipping';

// Product Related Components.
//@import 'components/product/card-product';
@import 'components/product/delivery-status';
//@import 'components/product/listing-active-filters';
//@import 'components/product/listing-filters';
@import 'components/product/listing-filters-item';
@import 'components/product/listing-filters-item-search';
@import 'components/product/listing-filters-title';
@import 'components/product/listing-items';
@import 'components/product/listing-sort';
@import 'components/product/product-slider';
@import 'components/product/product-small';
@import 'components/product/rating';
@import 'components/product/technical-table';
//@import 'components/product/article-item';

// Profile Related Components.
@import 'components/profile/edit/password';
@import 'components/profile/profile-entries/profile-entries';
@import 'components/profile/profile-entries/profile-entries-item';
@import 'components/profile/profile-entries/profile-entries-item-checkbox';
@import 'components/profile/profile-entries/profile-entries-item-radio';
@import 'components/profile/profile-entries/profile-entries-item-select';
@import 'components/profile/profile-entries/profile-entries-item-text';
@import 'components/profile/add-new-address';
@import 'components/profile/add-new-address-form';
@import 'components/profile/add-new-cart';
@import 'components/profile/add-new-cart-form';
@import 'components/profile/add-new-user';
@import 'components/profile/add-new-user-form';
@import 'components/profile/add-new-watchlist';
@import 'components/profile/add-new-watchlist-form';
@import 'components/profile/carts-item';
@import 'components/profile/default-addresses-form';

// Tabs.
@import 'components/tabs/tab-article-description';
@import 'components/tabs/tab-article-list';
@import 'components/tabs/tab-content-item';
@import 'components/tabs/tab-download';
@import 'components/tabs/tab-links';
@import 'components/tabs/tab-matching-it';
@import 'components/tabs/tab-reviews';

// Tiles.
@import 'components/tiles/bg-image-tile';
@import 'components/tiles/bg-tile';
@import 'components/tiles/default-tile';
@import 'components/tiles/definition-tile';
@import 'components/tiles/hover-tile';
//@import 'components/tiles/masonry-grid-tile';
@import 'components/tiles/popup-tile';
@import 'components/tiles/post-tile';
//@import 'components/tiles/process-tile';
@import 'components/tiles/side-image-tile';

// Product Variant Related Styling.
@import 'components/variants/variants-config-select';

// Other Components.
//@import 'components/accordion-item';
//@import 'components/aside-nav';
@import 'components/brand-image';
@import 'components/circled-image';
@import 'components/cookie-bar';
//@import 'components/count';
@import 'components/info-list-item';
@import 'components/label';
//@import 'components/mini-cart';
@import 'components/mini-compare';
@import 'components/mini-profile';
@import 'components/mini-watch-list';
@import 'components/news-slider-item';
@import 'components/pagination';
@import 'components/progress-bar';
@import 'components/read-more-text';
@import 'components/table-accordion';
@import 'components/table-scroller';
@import 'components/teaser-slide-item';
@import 'components/text';
@import 'components/user-administration-recorded-users-table-row';
@import 'components/watchlist-item';
@import 'components/search-result-products';
@import 'components/search-result-products-item';
@import 'components/step';
/* =================================================
        Plugins.
================================================= */

// Toastr.
@import 'plugins/toastr';
@import 'plugins/cookie-consent';
