@import '../../../components/header/header-search';

.header-search-submit {
  @include tabletMax {
    color: $colorBrightText;

    .header-search.mobile-open & {
      background-color: $colorTempUnavailable;
    }
  }
}

.header-search-input {
  &:focus {
    border: 1px solid $colorTempUnavailable;
  }
}

.header-search-input:focus {
  border: 1px solid $colorTempUnavailable;
}
