.tab-download-item {
  display: inline-block;
  vertical-align: middle;
  margin: 0 40px 20px 0;
  transition: color $transitionDurationDefault ease;

  i {
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 2px;
    margin: 0 10px 5px 0;
    color: $colorMineShaft;
    font-size: 25px;
    transition: color $transitionDurationDefault ease, background-color $transitionDurationDefault ease;
  }

  &--icon-fill i {
    background-color: $colorMineShaft;
    color: $colorBrightText;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    padding: 1px 0 0 1px;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: $colorActiveState;

    i {
      color: $colorActiveState;
    }
  }

  &--icon-fill:hover i {
    background-color: $colorActiveState;
    color: $colorBrightText;
  }

  @include tabletMax {
    display: block;
    margin: 0 0 20px;
  }
}
