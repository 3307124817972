.similar-products {
  max-width: 1920px;
  overflow: hidden;
}

.similar-products-title {
  &:not(:last-child) {
    margin-bottom: 25px;
  }
}

.similar-products-items {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.similar-products-container {
  margin-left: calc((100% - #{$containerMaxWidth}) / 2);
  max-width: calc(100% - ((100% - #{$containerMaxWidth}) / 2));

  .pagination-infinity {
    display: none;
  }

  @media screen and (max-width: 1360px) {
    margin-left: 0;
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }

  @include tabletMax {
    .swiper-wrapper {
      width: calc(100% + 10px);
      margin-left: -10px;
      margin-bottom: -20px;
      display: block;
      font-size: 0;
      height: auto !important;
    }

    .swiper-slide {
      display: inline-block;
      width: calc(50% - 10px);
      margin-left: 10px;
      height: auto;
      margin-bottom: 20px;
    }

    .pagination-infinity {
      display: block;
      margin-top: 20px;
    }

    .card-product {
      height: auto;
    }

    .swiper-pagination {
      display: none;
    }
  }

  @include mobileLargeMax {
    padding-left: 20px;
    padding-right: 20px;

    .swiper-wrapper {
      width: 100%;
      margin-left: 0;
    }

    .swiper-slide {
      width: 100%;
      margin-left: 0;
    }
  }

  @include mobileSmallMax {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.similar-products-load-more {
  margin-bottom: 25px;

  @include tabletMax {
    display: none;
  }
}
