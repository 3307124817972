.list-container {
  ol,
  ul {
    display: table;
    list-style: none;
    width: 100%;
  }

  li {
    display: table-row;

    &::before {
      content: '–';
      display: table-cell;
      width: 1px;
      padding-right: 0.3em;
    }

    &::after {
      content: '';
      display: block;
      margin-bottom: 0.4em;
    }

    ul,
    ol,
    li::after {
      margin-bottom: 0;
    }
  }

  ol {
    counter-reset: list-count;

    > li {
      &::before {
        content: counter(list-count) '.';
        counter-increment: list-count;
        text-align: right;
      }
    }
  }
}
