.footer-info {
  background-color: $colorMineShaft;
}

.footer-info-side {
  width: 55%;

  &:nth-child(even) {
    width: 45%;
  }

  &--dark {
    background-color: $colorCodGray;
  }

  @include tabletMax {
    width: 100%;

    &:nth-child(even) {
      width: 100%;
    }
  }
}

.footer-info-side-inner {
  margin-left: calc((181.81% - #{$containerMaxWidth}) / 2);
  margin-right: 0;
  padding: 50px 40px 70px 0;

  .footer-info-side:nth-child(even) & {
    margin-left: 0;
    padding: 50px 0 70px 40px;
    margin-right: calc((222.22% - #{$containerMaxWidth}) / 2);
  }

  @media screen and (max-width: 1355px) {
    margin-left: 0;
    margin-right: 0;
    max-width: none;
    padding-left: 40px;
    padding-right: 40px;

    .footer-info-side:nth-child(even) & {
      margin-right: 0;
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  @include mobileLargeMax {
    padding-left: 20px;
    padding-right: 20px;

    .footer-info-side:nth-child(even) & {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @include mobileSmallMax {
    padding-left: 10px;
    padding-right: 10px;

    .footer-info-side:nth-child(even) & {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
