$layout-hero-tiles--gutter: 18px !default;

.layout-hero-tiles-slider {
  position: relative;
  margin: -#{$layout-hero-tiles--gutter * 0.5} -#{$layout-hero-tiles--gutter * 0.5} 0;
}

.layout-hero-tiles-slider-inner {
  position: relative;
  padding: $layout-hero-tiles--gutter * 0.5;

  .swiper-button-next,
  .swiper-button-prev {
    opacity: 0;
    pointer-events: none;
    transition: opacity $transitionDurationDefault ease, color $transitionDurationDefault ease,
      background $transitionDurationDefault ease;
  }

  &:hover {
    .swiper-button-next,
    .swiper-button-prev {
      opacity: 0.84;
      pointer-events: auto;
    }

    .swiper-button-disabled {
      opacity: 0.7;
    }
  }
}

.layout-hero-tiles-slider-pagination {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
