.team-tile {
  overflow: hidden;
  position: relative;
}

.team-tile-main {
  img {
    height: auto;
  }
}

.team-tile-hover-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 34px 10px 34px 24px;
  background-color: $colorActiveState;
  color: $colorWhite;
  opacity: 0;
  transform: translateY(-3em);
  transition: opacity $transitionDurationDefault, transform $transitionDurationDefault;

  .team-tile:hover & {
    opacity: 1;
    transform: translateY(0);
  }
}

.team-tile-hover-content-footer {
  margin-top: auto;
}
