.tabs {
  @include tabletMax {
    padding: 0;
  }
}

.tabs-navigation {
  display: flex;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: calc(50% - 50vw);
    width: 100vw;
    height: 2px;
    background-color: $colorBlack;
  }

  @include tabletMax {
    display: none;
  }
}

.tabs-navigation-item {
  flex: 1 1 100%;
  padding: 11px 27px;
  margin-right: 21px;
  border: 2px solid $colorSilverChalice;
  border-bottom: none;
  background-color: $colorWhite;
  color: $colorSilverChalice;
  cursor: pointer;
  transition: all 0.4s;

  &:hover {
    border-color: $colorActiveState;
    color: $colorActiveState;
  }

  &.active {
    position: relative;
    border-color: $colorBlack;
    font-weight: 800;
    z-index: 2;
    color: $colorText;
  }

  &:last-child {
    margin-right: 0;
  }
}

.tabs-content {
  position: relative;
  transition: height 0.4s 0.4s;
}
