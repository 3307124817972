.footer-main-nav-side {
  width: calc(25% - 21px);
  margin-left: 21px;
  margin-bottom: 40px;

  @include desktopVerySmallMax {
    width: calc(50% - 21px);
  }

  @include mobileLargeMax {
    width: 100%;
    margin-left: 0;
    margin-bottom: 0;

    &:last-child {
      border-bottom: 1px solid $colorBrightText;
    }
  }
}

.footer-main-nav-side-title {
  &:not(:last-child) {
    margin-bottom: -1px;
    border-bottom: 1px solid #ffffff;
    line-height: 50px;
  }

  @include mobileLargeMax {
    position: relative;
    padding: 5px 20px;
    font-size: 20px;
    line-height: 27px;
    font-weight: 900;
    cursor: pointer;
    border-top: 1px solid $colorBrightText;

    &:not(:last-child) {
      margin-bottom: 0;
    }

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 28px;
      width: 1px;
      height: 18px;
      background-color: $colorBrightText;
      transform: translateY(-50%);
      transition: transform $transitionDurationDefault ease;
    }

    &::after,
    .open &::before {
      transform: translateY(-50%) rotate(90deg);
    }
  }

  @include mobileSmallMax {
    padding: 5px 10px;
    font-size: 18px;
    line-height: 24px;
  }
}

.footer-main-nav-side-items {
  @include mobileLargeMin {
    display: block !important; // Overwrite JS values.
  }

  @include mobileLargeMax {
    display: none;
  }
}

.footer-main-nav-side-item {
  position: relative;
  display: block;
  width: 100%;
  padding: 8px 20px 8px 5px;
  border-bottom: 1px solid $colorGallery;
  transition: color $transitionDurationDefault ease;

  i {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 10px;
    font-family: $fontIcons;
    transform: translateY(-50%);
  }

  &--has-arrow i {
    display: block;
  }

  &:hover {
    color: $colorGlacier;
  }

  @include mobileLargeMax {
    padding-left: 25px;
    padding-right: 25px;

    i {
      right: 25px;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  @include mobileSmallMax {
    padding-left: 15px;
    padding-right: 15px;

    i {
      right: 15px;
    }
  }
}
