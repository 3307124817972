.tab-links {
  display: inline-block;
  width: 210px;
  margin-left: 160px;
  color: $colorActiveState;
  font-weight: 700;
  line-height: 1.929;

  a {
    display: block;
    transition: color $transitionDurationDefault ease;

    &:hover {
      color: $colorGlacier;
    }
  }

  @include tabletMax() {
    margin-left: 40px;
  }

  @include mobileLargeMax() {
    margin-top: 30px;
    margin-left: 0;
    width: 100%;
  }
}
