/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion      process-tile bem module

*****************************************************************************/

/*
.process-tile

Styleguide classes.process-tile
*/

$process-tile-item--padding-right: 32px !default;
$process-tile-item--padding-left: 14px !default;
$process-tile-item--padding-top: 30px !default;

.process-tile {
  & + & {
    margin-top: 36px;
  }

  .layout-cart-table tr {
    border-width: 0;
  }

  .layout-edit-block-edit-footer {
    margin-top: $process-tile-item--padding-top * 0.5;
  }

  .layout-edit-block-edit-footer--valign-top {
    margin-top: 0;
  }
}

.process-tile-header,
.process-tile-item,
.process-tile-footer {
  padding: $process-tile-item--padding-top $process-tile-item--padding-right 40px $process-tile-item--padding-left;
  border: 1px solid $colorScorpion;
}

.process-tile-header,
.process-tile-footer {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.process-tile-header {
  transition: color $transitionDurationDefault ease;

  &:hover {
    color: $colorActiveState;
  }
}

.process-tile-header-icon {
  display: block;
  position: relative;
  width: 18px;
  height: 18px;
  margin-left: auto;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    margin: auto;
    background-color: $colorBlack;
    transform: rotate(180deg);
    transition: transform $transitionDurationDefault, background-color $transitionDurationDefault ease;

    .process-tile-header:hover & {
      background-color: $colorActiveState;
    }
  }

  &::after {
    transform: rotate(90deg);
  }

  .process-tile--active & {
    &::before,
    &::after {
      transform: rotate(0deg);
    }
  }
}

.process-tile-footer {
  border-top: none;
  background-color: $colorGallery;

  .icon-grid {
    font-size: 1.5em;
    vertical-align: middle;
  }
}

.process-tile-item {
  margin-top: -1px;
  border-top-width: 0;
  overflow: hidden;

  &--slim {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &--valign-center {
    align-items: center;
  }

  &--no-padding {
    padding: 0;
  }

  textarea {
    width: 100%;
    min-height: 130px;
  }
}
