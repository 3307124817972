.contact {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 69px;
  height: 69px;
  border-radius: 50%;
  text-align: center;
  background-color: $colorActiveState;
  z-index: $zNavigation - 30;
  transition: transform $transitionDurationDefault ease;

  i {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    font-size: 36px;
    color: $colorBrightText;
    padding-top: 2px;
    transform: translateY(-50%);
  }

  &:hover {
    transform: scale(1.1);
  }

  @include tabletMax {
    bottom: 68px;
  }
}
