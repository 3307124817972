.navigation-main-root-item {
  @include tabletMin {
    &[data-depth='0'] {
      position: relative;
    }
  }
}

.navigation-main-item-title {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  z-index: 2;
  transition: background-color $transitionDurationDefault ease, color $transitionDurationDefault ease;

  > div {
    font-weight: bold;
  }

  &.active {
    color: $colorActiveStateDarkened;
  }

  &:hover,
  .navigation-main-item:hover[data-depth='0'] > &,
  .navigation-main-item:hover[data-depth='0'] > app-menu-item > &,
  .navigation-main-item:hover[data-depth='1'] > &,
  .navigation-main-item:hover[data-depth='1'] > app-menu-item > & {
    color: $colorActiveState;
  }

  @include tabletMin {
    .navigation-main-item:hover[data-depth='1'] > app-menu-item > &,
    .navigation-main-item:hover[data-depth='1'] > & {
      color: $colorBrightText;
      background-color: $colorActiveState;
    }
  }

  @include tabletMax {
    position: relative;
    padding: 12px 40px;
    border-bottom: 2px solid $colorWhite;

    > div {
      font-weight: 400;
    }

    .navigation-main-item[data-depth='0']:first-child > app-menu-item > &,
    .navigation-main-item[data-depth='0']:first-child > & {
      padding-left: 40px;
    }

    .navigation-main-item[data-depth='0']:last-child > app-menu-item > &,
    .navigation-main-item[data-depth='0']:last-child > & {
      padding-right: 40px;
    }

    &:hover {
      background-color: rgba($colorWhite, 0.3);
    }
  }

  @include mobileLargeMax {
    padding: 12px 20px;
  }

  @include mobileSmallMax {
    padding: 12px 10px;
  }
}

// Special Styling for top layer title.
.navigation-main-item[data-depth='0'] > app-menu-item > .navigation-main-item-title,
.navigation-main-item[data-depth='0'] > .navigation-main-item-title {
  @include tabletMin {
    padding: 12px 10px !important;
  }

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    width: 50vw;
    background-color: $colorGallery;
    z-index: -1;

    @include desktopVerySmallMax {
      right: 15px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 0 0 40px;
    border-color: transparent transparent transparent $colorGallery;
    z-index: -1;

    @include desktopVerySmallMax {
      border-width: 50px 0 0 35px;
      left: calc(100% - 15px);
    }
  }

  @include tabletMax {
    &::before {
      display: none;
    }

    &:after {
      display: none;
    }
  }
}

.navigation-main-item[data-depth='0']:first-child > app-menu-item > .navigation-main-item-title,
.navigation-main-item[data-depth='0']:first-child > .navigation-main-item-title {
  padding-left: 0;

  @include tabletMax {
    padding-left: 40px;
  }

  @include mobileLargeMax {
    padding-left: 20px;
  }

  @include mobileSmallMax {
    padding-left: 10px;
  }
}

.navigation-main-item[data-depth='0']:last-child > app-menu-item > .navigation-main-item-title,
.navigation-main-item[data-depth='0']:last-child > .navigation-main-item-title {
  padding-right: 0;

  @include tabletMax {
    padding-right: 40px;
  }

  @include mobileLargeMax {
    padding-right: 20px;
  }

  @include mobileSmallMax {
    padding-right: 10px;
  }
}

.navigation-main-item[data-depth='1'] > app-menu-item > .navigation-main-item-title,
.navigation-main-item[data-depth='1'] > .navigation-main-item-title {
  @include tabletMin {
    padding: 11px 59px;
    border-bottom: 2px solid $colorWhite;
    background-color: $colorGallery;

    div {
      font-weight: 600;
      font-size: 18px;
      line-height: 1.278;
    }
  }
}

.navigation-main-item[data-depth='1']:last-child > app-menu-item > .navigation-main-item-title,
.navigation-main-item[data-depth='1']:last-child > .navigation-main-item-title {
  @include tabletMin {
    border-bottom: none;
  }
}

.navigation-main-item[data-depth='2'] {
  @include tabletMin {
    margin-bottom: 20px;

    > .navigation-main-item-title {
      padding: 0;
      margin-bottom: 10px;

      div {
        font-weight: 700;
        font-size: 13px;
        line-height: 1.357;
      }
    }

    .navigation-main-item-title {
      white-space: normal;
    }
  }
}

.navigation-main-item[data-depth='3'] > app-menu-item > .navigation-main-item-title,
.navigation-main-item[data-depth='3'] > .navigation-main-item-title {
  @include tabletMin {
    div {
      font-weight: 600;
      font-size: 13px;
      line-height: 1.5;
    }
  }
}

.navigation-main-item[data-depth='4'] > app-menu-item > .navigation-main-item-title,
.navigation-main-item[data-depth='4'] > .navigation-main-item-title,
.navigation-main-item[data-depth='5'] > app-menu-item > .navigation-main-item-title,
.navigation-main-item[data-depth='5'] > .navigation-main-item-title {
  @include tabletMin {
    div {
      font-weight: 400;
      font-size: 12px;
      line-height: 1.357;
    }
  }
}

.navigation-main-item[data-depth='3']:not(:last-child) > app-menu-item > .navigation-main-item-title,
.navigation-main-item[data-depth='3']:not(:last-child) > .navigation-main-item-title {
  @include tabletMin {
    margin-bottom: 12px;
  }
}

.navigation-main-item-opener {
  position: relative;
  width: 39px;
  height: 20px;
  margin-right: 21px;

  > div {
    position: absolute;
    top: 9px;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: $colorText;
    transition: background-color $transitionDurationDefault ease;

    &:first-child {
      top: 0;
    }

    &:last-child {
      top: auto;
      bottom: 0;
    }
  }

  .navigation-main-item:hover[data-depth='0'] & > div {
    background-color: $colorActiveState;
  }

  @include tabletMax {
    display: none;
  }
}

.navigation-main-items {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  background-color: $colorGallery;
  z-index: 5;

  @include tabletMin {
    ul {
      position: relative;
    }

    &[data-depth='1'] {
      max-height: calc(100vh - 320px);
      background-color: transparent;
      padding-top: 1px;
      overflow: hidden;
      direction: rtl; // Swaps scrollbar to left side.
      //height: calc(100vh - 320px);

      ul[data-depth='1'] {
        width: 945px;
        padding-right: 500px;
        direction: ltr;
      }
    }

    &[data-depth='2'] {
      display: none;
      width: 500px;
      height: 100%;
      background-color: $colorAlabaster;

      .navigation-main-item[data-depth='2'] {
        width: 100%;
      }

      ul[data-depth='2'] {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        overflow: auto;
        padding: 15px;
        height: 100%;
      }
    }

    &:not([data-depth='1']):not([data-depth='2']) {
      position: static;
      display: block;
      height: auto;
      background-color: $colorAlabaster;
    }

    .navigation-main-item[data-depth='0']:hover &[data-depth='1'],
    .navigation-main-item[data-depth='1']:hover &[data-depth='2'] {
      display: block;
    }

    &:not([data-depth='1']) {
      right: 0;
      left: auto;
      top: 0;
      height: 100%;
    }
  }

  @include desktopVerySmallMin {
    &[data-depth='1'] ul[data-depth='1'] {
      width: 1145px;
      padding-right: 700px;
    }

    &[data-depth='2'] {
      width: 700px;

      > ul > li[data-depth='2'] {
        width: calc(50% - 10px);
      }
    }
  }

  @include desktopSmallMin {
    &[data-depth='1'] ul[data-depth='1'] {
      width: 1245px;
      padding-right: 800px;
    }

    &[data-depth='2'] {
      width: 800px;
    }
  }

  @include desktopMin {
    &[data-depth='1'] ul[data-depth='1'] {
      width: 1325px;
      padding-right: 880px;
    }

    &[data-depth='2'] {
      width: 880px;

      > ul > li[data-depth='2'] {
        width: calc(25% - 15px);
        margin-bottom: 30px;
      }
    }
  }

  @media screen and (min-width: 1700px) {
    &[data-depth='1'] ul[data-depth='1'] {
      width: 1445px;
      padding-right: 1000px;
    }

    &[data-depth='2'] {
      width: 1000px;
    }
  }

  @media screen and (min-width: 1860px) {
    &[data-depth='1'] ul[data-depth='1'] {
      width: 1525px;
      padding-right: 1080px;
    }

    &[data-depth='2'] {
      width: 1080px;
    }
  }

  @include tabletMax {
    display: block;
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    transition: left $transitionDurationDefault ease;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    transform: none;

    .navigation-main-item.open > & {
      left: 0;
    }
  }
}

.navigation-main-item-open {
  display: none;
  position: absolute;
  top: 50%;
  right: 20px;
  font-size: 10px;
  padding: 20px;
  transform: translateY(-50%) rotate(-90deg);
  transition: color $transitionDurationDefault ease;

  .navigation-main-item-title:hover &,
  .navigation-main-item:hover[data-depth='0'] > .navigation-main-item-title &,
  .navigation-main-item:hover[data-depth='0'] > app-menu-item > .navigation-main-item-title &,
  .navigation-main-item:hover[data-depth='1'] > .navigation-main-item-title &,
  .navigation-main-item:hover[data-depth='1'] > app-menu-item > .navigation-main-item-title & {
    color: $colorActiveState;
  }

  @include tabletMin {
    .navigation-main-item[data-depth='1'] > app-menu-item > .navigation-main-item-title &,
    .navigation-main-item[data-depth='1'] > .navigation-main-item-title & {
      display: block;
    }

    .navigation-main-item[data-depth='1']:hover > app-menu-item > .navigation-main-item-title &,
    .navigation-main-item[data-depth='1']:hover > .navigation-main-item-title & {
      color: $colorWhite;
    }
  }

  @include tabletMax {
    display: block;
    right: 20px;
  }

  @include mobileLargeMax {
    right: 0;
  }

  @include mobileSmallMax {
    padding: 10px;
  }
}

.navigation-main-item-back {
  display: none;

  button {
    display: block;
    width: 100%;
    text-align: left;
    padding: 12px 40px;
    font-weight: 700;
    transition: color $transitionDurationDefault ease, background-color $transitionDurationDefault ease;
    border-bottom: 2px solid $colorWhite;
    background-color: rgba($colorSilverChalice, 0.2);
    color: black;

    &:hover {
      color: $colorActiveState;
      background-color: rgba($colorSilverChalice, 0.1);
    }

    @include mobileLargeMax {
      padding: 12px 20px;
    }

    @include mobileSmallMax {
      padding: 12px 10px;
    }
  }

  @include tabletMax {
    display: block;
  }
}

.navigation-main-item-discover-all {
  display: none;

  > a div {
    font-weight: bold;
  }

  @include tabletMax {
    display: block;
  }
}

.navigation-main-item[data-depth='1'] > a > a:hover {
  color: white !important;
}

.navigation-main-item-title:hover {
  .navigation-main-item-title {
    color: white!important;
  }
}

.navigation-main-item[data-depth='2']:hover > div > a {
  color: $colorActiveState;
}

.navigation-main-item[data-depth="2"] .navigation-main-item-title:hover {
  color: $colorActiveState !important;
}

.navigation-main-item[data-depth="0"]:last-child > .navigation-main-item-title:hover a {
  color: $colorActiveState !important;
}
