.header-user-info {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 6px;
  color: $colorSilverChalice;

  @include tabletMax {
    display: none;
  }
}

.header-user-info-icon {
  vertical-align: middle;
}
