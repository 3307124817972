.mat-step-header:hover:not([aria-disabled]) {
  background: none;
}

.mat-step-header .mat-step-icon-selected, .mat-step-icon-state-edit {
  background-color: $colorJaffa !important;
}

.mat-step-text-label{
  height: 16px;
}
