.choose-location-inner {
  padding: 20px;
  background-color: $colorGallery;

  select {
    width: 100%;
  }

  @include tabletSmallMin {
    padding: 40px;
  }

  @include tabletSmallMax {
    padding: 10px 13px;
  }
}

.choose-location-content {
  max-width: 400px;

  @include tabletSmallMax {
    max-width: 250px;
  }

  @include mobileLargeMax {
    width: 100%;
    max-width: none;
    margin: 0;
    text-align: left;
  }
}

.choose-location-title {
  &:not(:last-child) {
    margin-bottom: 9px;
  }

  @include tabletSmallMin {
    font-size: 22px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.choose-location-text {
  max-width: 200px;
  margin: 0 auto;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @include tabletSmallMin {
    max-width: 360px;

    .default-font-sizes {
      font-size: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  @include mobileLargeMax {
    width: 100%;
    max-width: none;
    margin: 0;
  }
}
