@import '../../../components/header/header-dropdown';

.header-dropdown-content {
  width: 450px;
  display: block;
  color: $colorText;
  pointer-events: none;
  opacity: 0;
  transition: opacity $transitionDurationDefault ease;

  .header-dropdown:hover & {
    pointer-events: auto;
    opacity: 1;
  }

  @include tabletMax {
    color: $colorWhite;
  }
}

.header-dropdown-label {
  background-color: transparent;
  color: $colorWhite;
  transition: background-color $transitionDurationDefault ease, color $transitionDurationDefault ease;
  max-width: 70px;
  margin: 0 auto;

  .header-dropdown:hover & {
    background-color: $colorWhite;
    color: $colorGlacier !important;
  }

  @include tabletMax {
    color: $colorWhite;
    background-color: transparent;

    .header-dropdown:hover & {
      background-color: transparent;
      color: $colorTempUnavailable !important;
    }
  }
}

.user-info {
  margin-left: 10px;
}

@media screen and (max-width: $tabletBreakpoint) {
  .header-dropdown-content {
    width: 450px;
    display: block;
    color: $colorText;
    pointer-events: none;
    opacity: 0;
    transition: opacity $transitionDurationDefault ease;

    .header-dropdown:hover & {
      pointer-events: auto;
      opacity: unset;
    }

    @include tabletMax {
      color: $colorWhite;
    }
  }
  .header-dropdown.active  {
    .header-dropdown-content {
      opacity: 1;
    }

  }
  .header-dropdown.inactive {
    .header-dropdown-content {
      opacity: 0;
    }
  }
}
