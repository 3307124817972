.videos {
  background-color: $colorMineShaft;
  padding-top: 66px;
  padding-bottom: 106px;

  @include mobileLargeMax {
    padding-top: 40px;
    padding-bottom: 50px;
  }
}

.videos-title:not(:last-child) {
  margin-bottom: 60px;

  @include tabletMax {
    margin-bottom: 40px;
  }

  @include mobileLargeMax {
    margin-bottom: 20px;
  }
}

.video-items {
  @include tabletMax {
    display: flex;
    flex-direction: column;
  }
}

.videos-videos {
  width: 66.3%;

  @include tabletMax {
    width: 100%;
  }
}

.videos-videos-inner {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.videos-video {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: -20px;
  opacity: 0;
  pointer-events: none;
  transition: opacity $transitionDurationDefault ease, top $transitionDurationDefault ease;
  z-index: 0;

  &.active {
    position: absolute;
    top: 0;
    pointer-events: auto;
    opacity: 1;
    z-index: 5;
  }

  iframe,
  video {
    height: 100%;
    width: 100%;
  }
}

.videos-navigation {
  width: 33.7%;
  padding-left: 20px;
  order: -1;

  @include tabletMax {
    width: 100%;
    padding-left: 0;

    &:not(:first-child) {
      margin-bottom: 20px;
    }
  }
}

.videos-navigation-item {
  border-bottom: 1px solid $colorScorpion;
  background-color: $colorMineShaft;
  transition: background-color $transitionDurationDefault ease;
  cursor: pointer;

  &:first-child {
    border-top: 1px solid $colorScorpion;
  }

  &.active,
  &:hover {
    background-color: $colorBlack;
  }
}

.videos-navigation-item-inner {
  padding: 13px 18px;
}

.videos-navigation-item-title {
  padding-right: 65px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.videos-navigation-item-infos {
  color: $colorScorpion;
  text-transform: uppercase;
}

.videos-navigation-item-progress {
  width: 100%;
  height: 3px;
  transition: height $transitionDurationDefault ease;
}

.videos-navigation-item-progress-bar {
  width: 0;
  height: 100%;
  background-color: $colorActiveState;
}

.videos-navigation-arrows {
  margin-top: 80px;
  text-align: right;

  @include tabletMax {
    display: none;
  }
}

.videos-navigation-arrow {
  display: inline-block;
  font-size: 20px;
  transform: rotate(90deg);
  color: $colorBrightText;
  transition: color $transitionDurationDefault ease;

  &[data-action='prev'] {
    transform: rotate(-90deg);
  }

  &:hover {
    color: $colorActiveState;
  }
}
