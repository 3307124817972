.profile-entries-item {
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.profile-entries-item-label {
  display: block;
  margin-bottom: 4px;
  color: $colorScorpion;

  @include tabletMax {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100px;
    margin-right: 14px;
  }
}

.profile-entries-item-content {
  display: block;
}
