.bg-tile {
  padding: 28px 16px 40px;
  background-color: $colorGallery;

  & + & {
    margin-top: 16px;
  }
}

.bg-tile-footer {
  margin-top: 60px;
  text-align: right;
}

.bg-tile-item {
  & + & {
    margin-top: 38px;
  }
}
