.link-hover,
.link-hover-container a {
  transition: color $transitionDurationDefault, background $transitionDurationDefault;
  color: inherit;

  &:hover {
    color: $colorCeruleanBlue;
  }
}

.to-products:hover {
  color: $colorCeruleanBlue !important;
}

.link-to-forgot {
  color: $colorCeruleanBlue !important;
}
