$bg-image-tile--padding-top: 40px !default;
$bg-image-tile--padding-side: 36px !default;
$bg-image-tile--padding-bottom: 34px !default;
$bg-image-tile--padding-bottom--tablet: 50px !default;

.bg-image-tile {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  min-height: 500px;
  max-height: 100%;
  padding: $bg-image-tile--padding-top $bg-image-tile--padding-side $bg-image-tile--padding-bottom;
  color: $colorWhite;
  background-size: cover;
  background-position: 50% 50%;

  @include tabletMax {
    padding-bottom: $bg-image-tile--padding-bottom--tablet;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(rgba($colorWhite, 0) 0%, rgba($colorBlack, 100%));
  }

  &--medium-height {
    min-height: 280px;
  }

  &--small-height {
    min-height: 206px;
  }
}

.bg-image-tile-title {
  margin-bottom: 12px;
  text-shadow: black 4px 4px 9px;
}

.bg-image-tile-main {
  position: relative;
  z-index: 10;
  padding: 0 $bg-image-tile--padding-side $bg-image-tile--padding-bottom;
  margin-right: $bg-image-tile--padding-side * -1;
  margin-bottom: $bg-image-tile--padding-bottom * -1;
  margin-left: $bg-image-tile--padding-side * -1;

  @include tabletMax {
    margin-bottom: $bg-image-tile--padding-bottom--tablet * -1;
    padding-bottom: $bg-image-tile--padding-bottom--tablet;
  }
}

.bg-image-tile-button {
  margin-top: 40px;
  .bg-image-tile-title + & {
    margin-top: 20px;
  }
}

.bg-image-tile-push {
  margin-top: auto;
}

.bg-image-tile-slider-pagination {
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
