@use 'sass:math';

$teaser-slide-item--gutter: $gutter !default;

.teaser-slide-item {
  display: flex;
  flex-wrap: wrap;
  margin: 0 math.div($teaser-slide-item--gutter, -2);

  @include tabletMax {
    display: block;
  }
}

.teaser-slide-item-header,
.teaser-slide-item-main,
.teaser-slide-item-footer {
  flex: 1 1 auto;
  padding: $teaser-slide-item--gutter * 0.5;
}

.teaser-slide-item-header {
  display: flex;
  flex-direction: column;
  width: math.div(100%, 12) * 4;

  @include tabletMax {
    width: auto;
  }
}

.teaser-slide-item-main {
  width: math.div(100%, 12) * 5;

  * + p {
    margin-top: 30px;
  }

  .btn {
    margin-top: 30px;
  }

  @include tabletMax {
    width: auto;
  }
}

.teaser-slide-item-footer {
  width: math.div(100%, 12) * 3;

  @include tabletMax {
    width: 100%;
  }
}

.teaser-slide-item-push {
  margin-top: auto;
}
