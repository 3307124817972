.header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 0 25px;
  transition: padding $transitionDurationDefault ease;

  .header.sticky:not(.open) & {
    padding: 25px 0;
  }

  @include tabletMax {
    padding: 25px 0;

    .header.sticky:not(.open) & {
      padding: 12px 0;
    }
  }

  @include tabletSmallMax {
    padding: 15px 0;

    .header.sticky:not(.open) & {
      padding: 12px 0;
    }
  }

  @include mobileLargeMax {
    padding: 7px 0;

    .header.sticky:not(.open) & {
      padding: 7px 0;
    }
  }
}

.header-main-opener {
  position: relative;
  display: none;
  width: 38px;
  height: 20px;
  margin-right: 24px;
  z-index: 2;

  div {
    position: absolute;
    top: 50%;
    height: 2px;
    background-color: $colorText;
    width: 100%;
    transform: translateY(-50%);
    transition: top $transitionDurationDefault ease, bottom $transitionDurationDefault ease,
      opacity $transitionDurationDefault ease, transform $transitionDurationDefault ease;

    &:first-child {
      top: 0;
      transform: none;
    }

    &:last-child {
      bottom: 0;
      top: auto;
      transform: none;
    }

    .open & {
      opacity: 0;

      &:first-child {
        opacity: 1;
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
      }

      &:last-child {
        opacity: 1;
        bottom: 50%;
        transform: translateY(50%) rotate(45deg);
      }
    }
  }

  @include tabletMax {
    display: block;
  }
}

.header-main-logo {
  width: 232px;

  img {
    width: 100%;
  }

  @include desktopVerySmallMax {
    width: 180px;
  }

  @include tabletMax {
    position: relative;
    z-index: 2;
  }

  @include tabletSmallMax {
    width: 130px;
  }
}
