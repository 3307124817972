app-scanner {
  video {
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.scanner-panel {
  .mat-dialog-container {
    padding: 0;
  }
}
