.listing-filters-item-search {
  position: relative;
  width: 100%;
  margin: 9px 0 20px;

  input {
    width: 100%;
    background-color: $colorGallery;
    border: none;
    padding: 9px 27px 9px 13px;

    &::placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      opacity: 1;
      color: $colorScorpion;
    }
  }

  button {
    position: absolute;
    top: 50%;
    right: 23px;
    font-size: 12px;
    color: $colorSilverChalice;
    transform: translateY(-50%);
    transition: color $transitionDurationDefault ease;

    &:hover {
      color: $colorText;
    }
  }

  @include tabletMax {
    padding: 0 10px;
    margin-bottom: 0;
  }
}
