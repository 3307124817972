.navigation-items {
  &.mobile {
    display: none;
  }

  @include tabletMin() {
    .catalog-menu {
      margin-right: 100px;
    }

    .cms-menu {
      flex: 1 1 auto;
      align-self: center;
      justify-content: space-between;
    }
  }

  @include tabletMax {
    flex-direction: column;

    &.mobile {
      display: block;
    }

    &:not(:last-child) {
      margin-bottom: 47px;
    }
  }
}

@include tabletMax {
  app-menu-item {
    &:not(:first-child) {
      border-top: 2px solid $colorWhite;
    }
  }
  .cms-menu {
    li:not(:first-child) {
      border-top: 2px solid $colorWhite;
    }
  }
}
