@use 'sass:math';

$layout-masonry-grid--gutter: 18px !default;
$layout-masonry-grid--ratio: math.div(300, 405);

.layout-masonry-grid {
  margin-right: math.div($layout-masonry-grid--gutter, -2);
  margin-left: math.div($layout-masonry-grid--gutter, -2);

  .masonry-grid-tile {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.layout-masonry-grid-item {
  position: relative;
  width: math.div(100%, 3);

  &::after {
    content: '';
    display: block;
    padding: ($layout-masonry-grid--ratio * 100%) 0 0;
  }

  &--1-2::after {
    padding-top: $layout-masonry-grid--ratio * 200%;
  }

  &--1-3::after {
    padding-top: $layout-masonry-grid--ratio * 300%;
  }

  &--2-2 {
    width: (math.div(100%, 3) * 2);
  }

  &--auto-height {
    &::after {
      content: none;
      display: none;
    }

    .masonry-grid-tile {
      position: relative;
    }
  }

  @include tabletMax {
    width: 50%;

    &::after {
      padding-top: 200%;
    }
  }

  @include mobileLargeMax {
    width: 100%;

    &::after {
      padding-top: 100%;
    }
  }
}
