/* =================================================
        Icons.
================================================= */

@font-face {
  font-family: 'icomoon';
  src: url($fontsBasePath + 'icons/fonts/icomoon.eot?36kwec');
  src: url($fontsBasePath + 'icons/fonts/icomoon.eot?36kwec#iefix') format('embedded-opentype'),
    url($fontsBasePath + 'icons/fonts/icomoon.ttf?36kwec') format('truetype'),
    url($fontsBasePath + 'icons/fonts/icomoon.woff?36kwec') format('woff'),
    url($fontsBasePath + 'icons/fonts/icomoon.svg?36kwec#icomoon') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $fontIcons !important;
  speak: never;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
  &--big {
    font-size: 22px;
    vertical-align: middle;
  }

  &--small {
    font-size: 14px;
  }

  &--grey-square {
    padding: 4px;
    background-color: $colorGallery;
  }

  &--mirror-x {
    display: inline-block;
    transform: scaleX(-1);
  }

  &--mirror-y {
    display: inline-block;
    transform: scaleY(-1);
  }

  &--rot-left {
    display: inline-block;
    transform: rotate(-90deg);
  }

  &--rot-right {
    display: inline-block;
    transform: rotate(90deg);
  }
}

.icon-contact::before {
  content: '\e91f';
}

.icon-share-2::before {
  content: '\e91e';
}

.icon-sortierung-black::before {
  content: '\e91b';
}

.icon-filter-white::before {
  content: '\e91d';
}

.icon-termin::before {
  content: '\e91c';
}

.icon-share-1::before {
  content: '\e91a';
}

.icon-service-2::before {
  content: '\e919';
}

.icon-marker-filled::before {
  content: '\e918';
}

.icon-ok::before {
  content: '\e913';
}

.icon-goto::before {
  content: '\e912';
}

.icon-fail::before {
  content: '\e911';
}

.icon-address::before {
  content: '\e904';
}

.icon-arrow::before {
  content: '\e900';
}

.icon-arrow-2::before {
  content: '\e901';
}

.icon-arrow-3::before {
  content: '\e902';
}

.icon-arrow-4::before {
  content: '\e903';
}

.icon-arrow-5::before {
  content: '\e905';
}

.icon-arrow-6::before {
  content: '\e906';
}

.icon-arrow-7::before {
  content: '\e907';
}

.icon-arrow-7-down::before {
  content: '\e908';
}

.icon-question::before {
  content: '\e909';
}

.icon-bag::before {
  content: '\e90a';
}

.icon-edit::before {
  content: '\e90b';
}

.icon-edit-2::before {
  content: '\e90c';
}

.icon-check::before {
  content: '\e90d';
}

.icon-close::before {
  content: '\e90e';
}

.icon-delivery::before {
  content: '\e90f';
}

.icon-download::before {
  content: '\e910';
}

.icon-eye::before {
  content: '\e914';
}

.icon-faq::before {
  content: '\e915';
}

.icon-branches::before {
  content: '\e916';
}

.icon-filter::before {
  content: '\e917';
}

.icon-money::before {
  content: '\e921';
}

.icon-burger::before {
  content: '\e922';
}

.icon-information::before {
  content: '\e923';
}

.icon-world::before {
  content: '\e925';
}

.icon-list::before {
  content: '\e926';
}

.icon-login::before {
  content: '\e927';
}

.icon-login-2::before {
  content: '\e928';
}

.icon-logo::before {
  content: '\e929';
}

.icon-logout::before {
  content: '\e92a';
}

.icon-magnify-plus::before {
  content: '\e92b';
}

.icon-magnify::before {
  content: '\e92c';
}

.icon-mail-filled::before {
  content: '\e92d';
}

.icon-mail::before {
  content: '\e930';
}

.icon-heart::before {
  content: '\e931';
}

.icon-heart-filled::before {
  content: '\e932';
}

.icon-bookmark::before {
  content: '\e933';
}

.icon-minus::before {
  content: '\e934';
}

.icon-filter-2::before {
  content: '\e935';
}

.icon-list-mobile::before {
  content: '\e93f';
}

.icon-grid-mobile::before {
  content: '\e940';
}

.icon-phone::before {
  content: '\e942';
}

.icon-plus::before {
  content: '\e943';
}

.icon-print::before {
  content: '\e944';
}

.icon-grid::before {
  content: '\e945';
}

.icon-percent::before {
  content: '\e946';
}

.icon-question-2::before {
  content: '\e947';
}

.icon-service::before {
  content: '\e949';
}

.icon-setting::before {
  content: '\e94a';
}

.icon-share::before {
  content: '\e94b';
}

.icon-shopping-bag::before {
  content: '\e94c';
}

.icon-map-marker::before {
  content: '\e94d';
}

.icon-star::before {
  content: '\e94e';
}

.icon-star-empty::before {
  content: '\e94f';
}

.icon-time::before {
  content: '\e950';
}

.icon-trash::before {
  content: '\e951';
}

.icon-compare::before {
  content: '\e952';
}

.icon-compare-2::before {
  content: '\e953';
}

.icon-cart::before {
  content: '\e954';
}

.header-nav {
  .icon-cart::before {
    color: $colorBlack;
  }
  .icon-heart-filled::before {
    color: $colorBlack;
  }
  .icon-login::before {
    color: $colorBlack;
  }
}

.icon-cart.white::before,
.icon-login.white::before {
  color: $colorWhite !important;
}

@include tabletMax {
  .icon-cart::before {
    content: '\e954';
    color: $colorWhite;
  }
  .icon-heart-filled::before {
    content: '\e932';
    color: $colorWhite;
  }
  .icon-login::before {
    content: '\e927';
    color: $colorWhite;
  }
  .icon-magnify::before {
    content: '\e92c';
    color: $colorBlack;
  }
}
