@import '../../base/variables';

/* ===========================================================
        Fonts.
=========================================================== */
$fontFutura: 'Futura';
$fontDefault: $fontFutura, 'Calibri', 'Arial', sans-serif;
$fontDefaultHeadline: $fontDefault;

/* ===========================================================
        Colors.
=========================================================== */
$colorBlack: #000000;
$colorCodGray: #1b1b1b;
$colorMineShaft: #393939;
$colorScorpion: #585858;
$colorSilverChalice: #afafaf;
$colorGallery: #f0f0f0;
$colorAlabaster: #fafafa;
$colorWhite: #ffffff;
$colorCeruleanBlue: #249bd3;
$colorGlacier: #249bd3;
$colorMonza: #d0021b;
$colorJaffa: #f29304;

// Generalized Colors.
$colorText: $colorBlack;
$colorBrightText: $colorWhite;
$colorAvailable: $colorCeruleanBlue;
$colorActiveState: $colorCeruleanBlue;
$colorUnavailable: $colorMonza;
$colorTempUnavailable: $colorJaffa;
