.form-error {
  display: flex;
  margin-top: 4px;
  margin-bottom: 10px;
  color: $colorUnavailable;
}

.form-error-icon {
  flex: 0 0 auto;
  margin-right: 10px;
  font-size: 18px;
}

.form-error-message {
  margin-top: 2px;
  flex: 1 1 auto;
}
