.footer-main-nav-side-support {
  width: calc(25% - 21px);
  margin-left: 21px;
  margin-bottom: 40px;

  @include desktopVerySmallMax {
    width: calc(50% - 21px);
  }

  @include mobileLargeMax {
    width: 100%;
    margin-left: 0;
    margin-bottom: 0;
  }
}

.footer-main-nav-side-support-title {
  &:not(:last-child) {
    margin-bottom: 18px;
  }

  @include mobileLargeMax {
    position: relative;
    padding: 5px 20px;
    font-size: 20px;
    line-height: 27px;
    font-weight: 900;
    cursor: pointer;
    border-top: 1px solid $colorBrightText;

    .footer-main-nav-side-support:last-child & {
      border-bottom: 1px solid $colorBrightText;
    }

    &:not(:last-child) {
      margin-bottom: 0;
    }

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 28px;
      width: 1px;
      height: 18px;
      background-color: $colorBrightText;
      transform: translateY(-50%);
      transition: transform $transitionDurationDefault ease;
    }

    &::after,
    .open &::before {
      transform: translateY(-50%) rotate(90deg);
    }
  }

  @include mobileSmallMax {
    padding: 5px 10px;
    font-size: 18px;
    line-height: 24px;
  }
}

.footer-main-nav-side-items {
  @include mobileLargeMin {
    display: block !important; // Overwrite JS values.
  }

  @include mobileLargeMax {
    display: none;
  }
}

.footer-main-nav-side-support-inner {
  @include mobileLargeMin {
    display: block !important;
  }

  @include mobileLargeMax {
    display: none;
  }
}

.footer-main-nav-side-support-info {
  padding: 9px 0;
  border-top: 1px solid $colorGallery;
  border-bottom: 1px solid $colorGallery;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  @include mobileLargeMax {
    padding-left: 20px;
    padding-right: 20px;

    .footer-main-nav-side-support:last-child & {
      border-top: none;
    }
  }

  @include mobileSmallMax {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.footer-main-nav-side-support-newsletter {
  @include mobileLargeMax {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mobileSmallMax {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.footer-main-nav-side-support-newsletter-title {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

@include mobileLargeMax {
  .footer-main-nav-side.open {
    .footer-main-nav-side-items {
      display: block !important;
    }
  }

  .footer-main-nav-side.closed {
    .footer-main-nav-side-items {
      display: none !important;
    }
  }
}
