.footer-notes {
  padding-top: 9px;
  padding-bottom: 9px;
}

.footer-notes-inner {
  @include tabletSmallMax {
    flex-direction: column;
  }
}

.footer-notes-nav {
  text-align: right;

  a {
    position: relative;
    display: inline-block;
    padding: 0 6px;
    transition: color $transitionDurationDefault ease;

    &:not(:first-child) {
      border-left: 1px solid $colorText;
    }

    &:hover {
      color: $colorActiveState;
    }
  }

  @include tabletSmallMax {
    text-align: left;
    order: -1;

    &:not(:first-child) {
      margin-bottom: 20px;
    }

    a {
      display: block;
      padding: 0;

      &:not(:first-child) {
        border-left: none;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}
