.news-title {
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @include mobileLargeMax {
    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
}

.news-slider {
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  @include tabletSmallMax {
    padding: 5px;
    margin-left: -5px;
    width: calc(100% + 10px);
  }
}
