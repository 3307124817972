.layout-fast-add-product-search-result {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  width: 100%;
  margin-top: 10px;
  background-color: $colorWhite;
  -webkit-box-shadow: 0 2px 7px 0 rgba($colorBlack, 0.35);
  -moz-box-shadow: 0 2px 7px 0 rgba($colorBlack, 0.35);
  box-shadow: 0 2px 7px 0 rgba($colorBlack, 0.35);
  z-index: 10;

  &.active {
    display: block;
  }

  @include tabletMax {
    left: 6px;
    margin-top: 5px;
    width: calc(100% - #{6px * 2});
  }
}
