/* =================================================
        Blocks. (Sections)
================================================= */

.block {
  &:not(:first-child) {
    margin-top: 50px;
  }

  &:not(:last-child) {
    margin-bottom: 50px;
  }

  @include mobileLargeMax {
    &:not(:first-child) {
      margin-top: 35px;
    }

    &:not(:last-child) {
      margin-bottom: 35px;
    }
  }
}
