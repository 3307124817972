@import '../../../includes/header/header';

.header-top {
  .default-padding:not(.header-info-container) {
    background-image: url($assetBasePath + '/images/header-bg.jpg');
    background-size: cover;
    background-position: center 0;
    background-repeat: no-repeat;
  }
}
