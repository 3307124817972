@import '../../../includes/header/header-detail';

.header-detail {
  .btn:hover {
    background-color: $colorGlacier;
    border-color: $colorGlacier;
  }
}

.header-detail-top:not(:last-child) {
  margin-bottom: 50px;
}
