.header-search-result-products {
  padding: 20px 26px 10px 26px;

  &:not(:last-child) {
    border-bottom: 1px solid $colorSilverChalice;
  }
}

.header-search-result-products-title {
  position: relative;
  padding-right: 65px;
  color: $colorSilverChalice;

  &:not(:last-child) {
    margin-bottom: 9px;
  }
}

.header-search-result-products-title-count {
  font-weight: 400;
}

.header-search-result-products-link {
  position: absolute;
  right: 0;
  font-weight: 400;
  transition: color $transitionDurationDefault ease;

  &:hover {
    color: $colorActiveState;
  }

  i {
    display: inline-block;
    vertical-align: middle;
    font-size: 10px;
    padding-bottom: 2px;
  }
}
