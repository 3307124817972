@import '../../base/edit-hover';

.edit-hover {
  .edit-hover-container:hover &,
  &:hover {
    &::after {
      background-color: $colorTempUnavailable;
    }
  }
}
