@import '../../base/bg-section';

.bg-section {
  &--blue-light {
    color: $colorBrightText;
    background: linear-gradient(180deg, rgba(0, 102, 80, 1) 0%, rgba(25, 61, 52, 1) 100%);

    input[type='checkbox'] + label {
      color: $colorBrightText;

      &::before {
        border-color: $colorBrightText;
      }
    }

    input[type='checkbox'] + label:hover,
    input[type='checkbox']:checked + label {
      color: $colorBrightText;

      &::before {
        border-color: $colorBrightText;
      }

      &:after {
        color: $colorBrightText;
      }
    }

    input[type='submit'] {
      border-color: $colorWhite;
      background-color: $colorWhite;
      color: $colorText;

      &:hover {
        color: $colorWhite;
        border-color: $colorTempUnavailable;
        background-color: $colorTempUnavailable;
      }
    }
  }
}
