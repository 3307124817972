.aside-nav-title {
  font-weight: 700;
  color: $colorCeruleanBlue !important;
  margin-bottom: 20px;
}

.aside-nav-item {
  a {
    display: inline-block;
    width: 100%;
    padding: 5px;
    transform: translateX(0);
    transition: background-color $transitionDurationDefault ease, padding-left $transitionDurationDefault ease,
      color $transitionDurationDefault ease;
  }

  &:hover a,
  &--is-active a {
    color: $colorCeruleanBlue !important;
    padding-left: 9px;
    background-color: $colorGallery;
  }

  &--is-active a {
    font-weight: 700;
  }
}

.aside-nav-footer {
  margin-top: 24px;
  padding: 15px 0;
  border-top: 1px solid $colorBlack;

  a {
    display: inline-block;
    width: 100%;
    padding: 5px;
    transition: background-color $transitionDurationDefault ease, padding-left $transitionDurationDefault ease,
      color $transitionDurationDefault ease;
  }

  & + .aside-nav-back {
    margin-top: 0;
  }

  &:hover a,
  &--is-active a {
    color: $colorCeruleanBlue !important;
    padding-left: 9px;
    background-color: $colorGallery;
  }

  &--is-active a {
    font-weight: 700;
  }
}

.aside-nav-back {
  margin-top: 50px;
  border-top: 1px solid $colorBlack;
  padding-top: 16px;

  a {
    transition: color $transitionDurationDefault ease;

    &:hover {
      color: $colorCeruleanBlue !important;
    }
  }

  [class*='icon-'] {
    font-size: 0.8em;
  }
}
