.edit-link {
  transition: color $transitionDurationDefault, opacity $transitionDurationDefault;

  &:hover {
    opacity: 1;
  }

  [class*='icon-'] {
    vertical-align: middle;
  }

  .icon-edit {
    font-size: 1.4em;
  }

  .icon-goto {
    font-size: 0.95em;
  }
}
