@import '../../../components/tiles/masonry-grid-tile';

.masonry-grid-tile-teaser {
  .btn {
    background-color: $colorGallery;
    border-color: $colorText;
    color: $colorText;

    &:hover {
      background-color: $colorTempUnavailable;
      border-color: $colorTempUnavailable;
      color: $colorBrightText;
    }
  }
}
