/* =================================================
        Plugin Overrides.
================================================= */
$breakpoints: (
  'desk' '(min-width: 1024px)',
  'small-desk' '(max-width: 1224px)',
  'portable' '(max-width: 1023px)',
  'lap' '(min-width: 769px) and (max-width: 1023px)',
  'palm' '(max-width: 768px)'
);
$breakpoint-has-widths: ('desk', 'small-desk', 'portable', 'lap', 'palm') !default;
