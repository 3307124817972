.side-image-tile {
  display: flex;
  align-items: flex-start;
  margin-bottom: 18px;
  padding: 18px 13px 15px;
  color: $colorBlack;
  background-color: $colorGallery;
  transition: color $transitionDurationDefault, background $transitionDurationDefault;

  &:hover {
    color: $colorWhite;
    background-color: $colorMineShaft;
    transition-duration: 0.3s;
  }
}

.side-image-tile-main {
  flex: 1 1 auto;
  padding-right: 10px;
}

.side-image-tile-footer {
  flex: 0 0 auto;
  width: 123px;

  img {
    transform: scale(1);
    transition: transform $transitionDurationDefault;

    .side-image-tile:hover & {
      transform: scale(1.05);
      transition-duration: 0.2s;
    }
  }
}

.side-image-tile-title {
  margin-bottom: 13px;
}
