@import '../../components/count';

.count {
  background-color: $colorTempUnavailable;
}

.icon-wrapper {
  position: relative;
}

.badge {
  background: $colorTempUnavailable;
  position: absolute;
  top: 5px;
  right: -8px;
  vertical-align: middle;
  padding: 3px 5px 3px;
  border-radius: 7px/50%;
  font-size: 13px;
  line-height: 1;
  color: #ffffff;
  display: inline-block;
  font-weight: 400;
  font-family: $fontDefault;
}
