/*
Product Card

A product card for listings.

Markup: components/product/card-product.html

Style guide: 1
*/
.card-product {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border: 2px solid $colorSilverChalice;
  transition: box-shadow $transitionDurationDefault ease, border $transitionDurationDefault ease;
  flex-grow: 1;
  flex-shrink: 0;

  &:hover {
    box-shadow: 0 1px 10px 0 rgba($colorBlack, 0.35);
  }

  .no-touchevents & {
    .quantity-select {
      opacity: 0;
      transition: opacity $transitionDurationDefault ease;
      pointer-events: none;
    }

    &:hover {
      .quantity-select {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  .list & {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    min-height: 250px;

    .delivery-status-dot {
      width: 6px;
      height: 6px;
      margin-bottom: 2px;
    }

    .no-touchevents & {
      .quantity-select {
        width: 0;
        animation-name: quantitySelectSizeDown;
        animation-fill-mode: forwards;
        animation-duration: $transitionDurationDefault;
      }

      &:hover {
        /* stylelint-disable */
        .quantity-select {
          animation-name: quantitySelectSizeUp;
        }
        /* stylelint-enable */
      }
    }

    &:hover {
      box-shadow: 0 2px 7px 0 rgba($colorBlack, 0.8);
    }
  }
}

.card-product-top {
  position: relative;
  display: block;
  height: 315px;
  //background-color: $colorGallery;

  .list & {
    width: 140px;
    height: auto;
  }

  .delivery-status {
    position: absolute;
    left: 7px;
    top: 7px;
  }
}

.card-product-top-image {
  position: absolute;
  left: 17px;
  top: 17px;
  width: calc(100% - #{17px * 2});
  height: calc(100% - #{17px * 2});
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.card-product-top-buttons {
  position: absolute;
  top: 15px;
  right: 13px;
  cursor: pointer;
  font-size: 20px;

  &:hover {
    color: $colorActiveState;
    transition: color $transitionDurationDefault ease;
  }

  .list & {
    display: none;
  }

  &--list {
    position: relative;
    top: auto;
    right: auto;
    display: none;
    margin-right: 10px;

    .list & {
      display: flex;
      align-items: center;
    }

    button:not(:last-child) {
      margin-bottom: 0;
      margin-right: 7px;
    }
  }

  @include tabletMax {
    display: none;

    &--list {
      position: relative;
      top: auto;
      right: auto;
      display: flex;
      align-items: center;
      margin-right: 10px;

      button {
        height: 100%;
        width: 42px;
      }

      button:not(:last-child) {
        margin: 0;
      }

      .list & {
        button {
          width: 35px;
        }
      }
    }
  }
}

.card-product-list-info {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;

  .card-product-discount {
    padding: 7px 11px 5px;
  }

  .list & {
    display: block;
  }
}

.card-product-configuration {
  background-color: $colorScorpion;
  color: $colorBrightText;
  line-height: 20px;
}

.card-product-bottom {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  z-index: 2;

  .list & {
    width: calc(100% - 240px);

    @include mobileLargeMax {
      min-width: 200px;
    }
  }
}

.card-product-layover {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $colorWhite;
  transition: transform $transitionDurationDefault ease;
  border-bottom: 2px solid $colorGallery;
  z-index: -1;

  .list & {
    display: none;
  }

  .card-product:hover & {
    transform: translateY(-100%);
  }

  .touchevents & {
    position: static;
    z-index: 0;
    border-bottom: none;
  }

  .touchevents .card-product:hover & {
    transform: none;
  }
}

.card-product-discount {
  position: absolute;
  top: 0;
  left: 0;
  padding: 6px 5px 5px;
  background-color: $colorAvailable;
  color: $colorBrightText;
  transform: translateY(-100%);
}

.card-product-description,
.card-product-layover-configuration-options {
  padding: 15px 13px;
}

.card-product-layover-configuration-options {
  max-height: 220px;
  overflow: auto;

  .touchevents & {
    padding: 0;
    margin-top: 10px;
    max-height: none;

    @include tabletMax {
      padding: 10px;
    }
  }
}

.card-product-layover-configuration-title {
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.card-product-layover-configuration-option {
  select {
    width: 100%;
  }

  &:not(:last-child) {
    margin-bottom: 15px;

    .touchevents & {
      margin-bottom: 8px;
    }
  }
}

.card-product-layover-configuration-option-title {
  display: block;

  &:not(:last-child) {
    margin-bottom: 3px;
  }

  .touchevents & {
    display: none;
  }
}

.card-product-description {
  background-color: $colorWhite;
  width: 100%;

  .rating {
    flex-shrink: 0;

    &:not(:first-child) {
      padding-left: 10px;
    }
  }

  @include tabletMax {
    padding: 0;
  }
}

.card-product-description-art-nr {
  color: $colorScorpion;
}

.card-product-description-top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include tabletMax {
    flex-direction: column-reverse;
    padding: 10px 10px 0;

    .rating {
      text-align: right;
      width: 100%;
    }

    .list & {
      flex-direction: row;
      padding: 2px 5px 0;

      .rating {
        padding-top: 2px;
        width: auto;
      }
    }
  }
}

.card-product-description-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: row-reverse;

  &:not(:first-child) {
    margin-top: 15px;
  }

  @include tabletMax {
    flex-direction: column;
    align-items: flex-start;

    &:not(:first-child) {
      margin-top: 20px;
    }

    .list & {
      flex-direction: row;
      align-items: flex-end;
    }
  }

  @include mobileSmallMax {
    .list & {
      flex-wrap: wrap;
    }
  }
}

.card-product-description-bottom-price {
  min-width: 120px;

  .list & {
    min-width: 0;
    white-space: nowrap;
  }

  @include tabletMax {
    padding: 0 0 10px 10px;

    .list & {
      padding: 0 0 2px 5px;
    }
  }
}

.card-product-pseudo-price {
  // position: absolute;
  bottom: 100%;
  left: 0;

  @include tabletMax {
    left: 10px;

    .list & {
      left: 5px;
    }
  }
}

.card-product-description-bottom-cart {
  display: flex;
  height: 35px;

  @include tabletMax {
    width: 100%;
    justify-content: space-between;

    .list & {
      justify-content: flex-end;
    }
  }
}

.card-product-description-bottom-cart-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 35px;
  font-size: 19px;
  color: $colorText;
  transition: color $transitionDurationDefault ease, background-color $transitionDurationDefault ease;

  .touchevents &,
  .card-product:hover & {
    color: $colorBrightText;
    background-color: $colorActiveState;
  }

  .card-product:hover &:hover,
  &:hover {
    background-color: rgba($colorActiveState, 0.9);
  }

  &--no-config {
    display: none;
  }

  .touchevents .card-product--no-mobile-config & {
    display: none;

    &--no-config {
      display: flex;
    }
  }
}

.card-product-layover-configuration-option-colors {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: -5px;
}

.card-product-layover-configuration-option-color {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  padding: 4px;
  margin-bottom: 5px;

  &:not(:last-child) {
    margin-right: 5px;
  }

  &.active {
    border: 2px solid $colorSilverChalice;
  }

  img {
    max-width: 100%;
  }
}

.card-product {
  .article-container {
    border: none !important;
    margin-bottom: 0 !important;
  }
  .right-side {
    width: 40% !important;
  }
}

.list .card-product-top {
  min-width: 21%;
  border-right: 1px solid #afafaf;
  margin-right: 10px;
}
