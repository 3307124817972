.tab-article-list-magnify {
  transition: color $transitionDurationDefault ease;

  &:hover {
    color: $colorActiveState;

    i {
      background-color: $colorActiveState;
      color: $colorBrightText;
    }
  }

  i {
    transition: color $transitionDurationDefault ease, background-color $transitionDurationDefault ease;
  }
}
