.header-dropdown {
  position: relative;
  filter: drop-shadow(0px 0px 0px rgba($colorBlack, 0));

  &:hover,
  .touchevents &:focus,
  &.active {
    z-index: 10;
    transition: filter 0.2s;
    transition-delay: 0s;

    .header-dropdown-content,
    .header-dropdown-label {
      box-shadow: 0px 7px 7px rgba($colorBlack, 0.35);
    }

    @supports (filter: drop-shadow(0px 2px 7px rgba($colorBlack, 0.35))) {
      filter: drop-shadow(0px 2px 7px rgba($colorBlack, 0.35));

      .header-dropdown-content,
      .header-dropdown-label {
        box-shadow: none;
      }
    }

    @include tabletMax {
      .touchevents &,
      .touchevents &:focus {
        filter: none;
      }
    }
  }

  @include tabletMax {
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: none;
    width: 70px;
    height: 70px;

    &:hover,
    &:focus,
    &.active {
      filter: none;
    }
  }
}

.header-dropdown-label {
  position: relative;
  padding: 20px 20px;
  font-size: 26px;
  color: $colorSilverChalice;
  background-color: $colorBrightText;
  cursor: pointer;
  transition: color $transitionDurationDefault ease;
  max-width: 70px;
  margin: 0 auto;
  height: 60px;

  .header-dropdown:hover &,
  .touchevents .header-dropdown:focus &,
  .header-dropdown.active & {
    color: $colorActiveState;
  }

  @include tabletMax {
    padding: 0;
    background-color: transparent;
    color: $colorBrightText;
  }
}

.header-dropdown-count {
  position: absolute;
  top: 14px;
  right: 14px;
}

.header-dropdown-content {
  display: none;
  overflow: auto;
  position: absolute;
  top: 100%;
  right: 0;
  width: 422px;
  max-width: 100vw;
  max-height: calc(100vh - 142px);
  text-align: left;
  background-color: $colorBrightText;

  .header-dropdown.active &,
  .touchevents .header-dropdown:focus &,
  .header-dropdown:hover & {
    display: block;
  }

  @include tabletMax {
    top: auto;
    bottom: 100%;
    left: 0;
    right: auto;
    width: 100%;
    max-width: none;
    max-height: 385px !important; // ToDo: ?
    overflow: auto;
    text-align: left;
    background-color: $colorMineShaft;
  }
}

.header-dropdown-content-mobile-header {
  display: none !important;
  padding: 13px 10px 14px;
  border-top: 1px solid $colorWhite;
  border-bottom: 1px solid $colorWhite;
  font-size: 18px;
  font-weight: bolder;
  color: $colorWhite;
  background-color: $colorBlack;

  @include tabletMax {
    display: flex !important;
  }
}

.header-dropdown-content-mobile-header-icon {
  display: inline-block;
  margin-right: 10px;
  font-size: 1.1em;
  vertical-align: middle;
}

@include tabletMax {
  .header-dropdown-label {
    height: 30px;
  }
}
