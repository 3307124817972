.definition-tile-header,
.definition-tile-footer,
.definition-tile-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 72px;
  padding: 12px 11px;
  white-space: nowrap;

  @include tabletMax {
    white-space: normal;
  }
}

.definition-tile-header,
.definition-tile-footer {
  background-color: $colorGallery;
  border-top: 1px solid $colorScorpion;
  border-bottom: 1px solid $colorScorpion;
}

.definition-tile-item {
  & + & {
    border-top: 1px solid $colorScorpion;
  }

  &--stretch {
    justify-content: stretch;

    > * + * {
      margin-left: 22px;
    }
  }
}

.definition-tile-item-center {
  flex: 1 1 80%;
}
