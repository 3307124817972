/* =================================================
        Read More Text.
================================================= */

.read-more-text {
  position: relative;
  max-height: 100px;
  overflow: hidden;
  transition: max-height $transitionDurationDefault ease;

  &.open {
    max-height: 500px;
  }

  &[data-only-mobile='true'] {
    max-height: none;
    overflow: visible;

    .read-more-text-button {
      display: none;
    }

    &.open {
      max-height: none;
    }

    @include tabletSmallMax {
      max-height: 100px;
      overflow: hidden;

      &.open {
        max-height: 500px;
      }

      .read-more-text-button {
        display: flex;
      }
    }
  }
}

.read-more-text-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: $colorWhite;
  border-top: 2px solid $colorSilverChalice;
  border-bottom: 2px solid $colorSilverChalice;
  padding: 1px 0;
  color: $colorScorpion;
  transition: opacity $transitionDurationDefault ease, color $transitionDurationDefault ease,
    border-color $transitionDurationDefault ease;

  .read-more-text.open & {
    opacity: 0;
    pointer-events: none;
  }

  i {
    margin-bottom: 2px;

    &:last-child {
      margin-left: 8px;
    }

    &:first-child {
      margin-right: 8px;
    }
  }

  &:hover {
    color: $colorText;
    border-color: $colorText;
  }
}
