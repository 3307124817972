.table-outer {
  position: relative;
  width: 100%;

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 20px;
    pointer-events: none;
    opacity: 0;
    transition: opacity $transitionDurationDefault ease;
  }

  &::before {
    left: 0;
    background: linear-gradient(90deg, rgba($colorBlack, 0.35) 0%, rgba($colorWhite, 0) 100%);
  }

  &::after {
    right: 0;
    background: linear-gradient(-90deg, rgba($colorBlack, 0.35) 0%, rgba($colorWhite, 0) 100%);
  }

  &.shadow-right::after,
  &.shadow-left::before {
    opacity: 1;
  }
}

.table-scroller {
  width: 100%;
  overflow: auto;
}

.table {
  width: 100%;
  text-align: left;

  td > div,
  th {
    padding: 9px 13px;
  }

  td {
    padding: 0;
    vertical-align: middle;
  }

  th {
    border-top: 1px solid $colorBlack;
    border-bottom: 1px solid $colorBlack;
  }

  &--products {
    min-width: 1260px;
  }
}

.table-row {
  &--grey {
    background-color: $colorGallery;
  }
}
