.layout-cart-table {
  position: relative;
  border: 1px solid $colorSilverChalice;

  &--no-border {
    border: none;
  }

  @include tabletMax {
    border: none;

    table,
    tbody,
    tfoot {
      display: block;
    }

    thead {
      display: none;
    }

    tr {
      display: flex; // Sets display to flex if grid is not available.
      flex-wrap: wrap;
      display: grid;
      grid-template-columns: repeat(4, minmax(10px, 1fr));
      grid-auto-flow: dense;
      border: 1px solid $colorSilverChalice;
    }

    tfoot tr {
      display: block;
      border: none;
      margin-top: 20px;
    }

    tfoot td {
      &:empty {
        display: none;
      }

      &:last-child {
        text-align: right;
      }
    }

    tr + tr {
      margin-top: 10px;
    }
  }

  strong {
    font-weight: 700;
  }

  tr + tr &-cell {
    border-top: 1px solid $colorSilverChalice;

    @include tabletMax {
      &:not(&-cell--mobile-border) {
        border: none;
      }
    }
  }
}

.layout-cart-table-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 10px 16px 38px;

  @include tabletMax {
    padding-left: 0;
  }
}

.layout-cart-table-header--alignment-left {
  padding-left: 11px;
}

.layout-cart-table-title {
  & + * {
    margin-left: auto;

    @include mobileLargeMax {
      margin-left: 0;
    }
  }

  .icon-edit {
    font-size: 20px;
  }

  @include tabletSmallMax {
    display: none;
  }
}

.layout-cart-table-title-inner {
  quotes: '«' '»';

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }
}

.layout-cart-table-table {
  width: 100%;
  text-align: left;
  border-collapse: separate;

  strong {
    font-weight: bolder;
  }
}

.layout-cart-table-cell {
  padding: 20px 10px 20px 11px;
  vertical-align: top;

  &--slim {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &--image {
    width: 124px + 10px + 11px;

    img {
      max-width: 100%;
    }
  }

  &--watchlist-image {
    width: 90px;
    padding-right: 0;
    padding-left: 0;
  }

  &--footer {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &--mobile-border {
    @include tabletMax {
      border-top: 1px solid $colorSilverChalice !important;
      border-bottom: 1px solid $colorSilverChalice !important;
    }

    .layout-cart-table-top {
      @include tabletMax {
        border-top: 1px solid $colorSilverChalice !important;
      }
    }

    .layout-cart-table-bottom {
      @include tabletMax {
        border-bottom: 1px solid $colorSilverChalice !important;
      }
    }
  }

  &--mobile-row {
    @include tabletMax {
      @for $i from 1 through 5 {
        &-#{$i} {
          grid-row-start: $i;
        }
      }
    }
  }

  &--mobile-column {
    @include tabletMax {
      @for $i from 1 through 4 {
        &-#{$i} {
          grid-column-start: $i;
        }
      }
    }
  }

  &--mobile-row-span {
    @include tabletMax {
      @for $i from 2 through 5 {
        &-#{$i} {
          grid-row: span $i;
        }
      }
    }
  }

  &--mobile-column-span {
    @include tabletMax {
      @for $i from 2 through 4 {
        &-#{$i} {
          grid-column: span $i;
        }
      }
    }
  }

  &--middle {
    vertical-align: middle;
  }

  &--bottom {
    vertical-align: bottom;
  }

  &--top-padding {
    padding-top: 56px;

    @include tabletMax {
      padding-top: 10px;
    }
  }

  &--bottom-padding {
    padding-bottom: 54px;

    @include tabletMax {
      padding-bottom: 10px;
    }
  }

  @include tabletMax {
    display: block;
    padding: 10px;

    &--image {
      width: auto;

      img {
        width: 100%;
      }
    }

    &--text-content {
      grid-column: span 3;
    }

    &--delivery-status {
      order: 10;
      align-self: center;
      grid-column: span 2;
      margin-right: auto;
    }

    &--quantity {
      order: 11;
      margin-left: auto;
    }

    &--price,
    &--price-total {
      display: flex;
      flex-direction: column;
      justify-content: center;

      br {
        display: none;
      }
    }

    &--price {
      order: 11;
      grid-column: span 2;
    }

    &--price-total {
      order: 11;
      grid-column: span 2;
      text-align: right;
    }
  }

  @include mobileSmallMax {
    &--quantity {
      > * {
        margin-left: -4em;
      }
    }
  }

  [class*='icon-'] {
    vertical-align: middle;
  }

  .layout-cart-table-cell-icon-edit {
    font-size: 1.8em;
  }

  .layout-cart-table-cell-icon-edit-2 {
    font-size: 1.4em;
  }

  &--icon {
    width: 1px;

    @include tabletMax {
      align-self: center;
      width: auto;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  &--mobile-footer {
    @include tabletMax {
      order: 20;
      align-self: center;
      width: auto;
      padding-top: 10px;
      padding-bottom: 10px;

      &-1 {
        order: 20;
        margin-right: auto;
      }

      &-2 {
        order: 21;
        margin-right: auto;
        transform: translateX(50%);
      }

      &-3 {
        order: 22;
        margin-left: auto;
        transform: translateX(-50%);
      }

      &-4 {
        order: 23;
        margin-left: auto;
      }
    }
  }
}

.layout-cart-table-additional-services {
  margin-top: 10px;

  @include mobileLargeMax {
    margin-top: 40px;
    @supports (display: grid) {
      margin-left: -33.33%;
    }
  }
}

.layout-cart-table-header-cell {
  background-color: $colorGallery;
  vertical-align: middle;
  //margin-left: -20px; AWI because of the user-administration table in profile

  & + & {
    border-left: 1px solid $colorWhite;
    padding-left: 10px;
  }

  &--icon {
    width: 1px;
  }

  &--passive {
    color: rgba($colorText, 0.4); // ToDo: Check with Design, white is basically not visible on some screens.
  }

  &--no-border {
    & + & {
      border-left-color: transparent;
    }
  }
}

.layout-cart-table-tr-footer {
  padding-top: 20px;
  background-color: $colorGallery;

  &-total {
    @include tabletMax {
      font-size: 1.2em;
    }
  }
}

.layout-cart-table-control {
  display: flex;
  align-items: center;

  @include mobileLargeMax {
    flex: 0 0 100%;
    display: block;
  }

  > * + * {
    margin-left: 40px;

    @include mobileLargeMax {
      display: block;
      margin-left: 0;
      margin-top: 10px;
    }
  }
}

.layout-cart-table-control-remove-all {
  @include mobileLargeMax {
    flex: 0 0 100%;
    text-align: right;
  }
}
