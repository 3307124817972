/* =================================================
        Helper classes.
================================================= */

.text-centered {
  text-align: center;
}

.text-right {
  text-align: right;
}

.inline-container {
  font-size: 0; // Fixes inline-block whitespace.
}

.whitespace-nowrap {
  white-space: nowrap;
}

.inline,
.inline-middle,
.inline-bottom,
.inline-top {
  display: inline-block;
}

.inline-middle {
  vertical-align: middle;
}

.inline-bottom {
  vertical-align: bottom;
}

.inline-top {
  vertical-align: top;
}

.hidden {
  display: none;
}

.invisible {
  position: absolute !important;
  left: -9999em !important;
}

.tablet-show-inline-block,
.tablet-show-inline,
.tablet-show-flex,
.tablet-show {
  display: none;

  @include tabletMax {
    display: block;
  }
}

.tablet-show-inline-block {
  @include tabletMax {
    display: inline-block;
  }
}

.tablet-show-inline {
  @include tabletMax {
    display: inline;
  }
}

.tablet-show-flex {
  @include tabletMax {
    display: flex;
  }
}

.tablet-hidden {
  @include tabletMax() {
    display: none;
  }
}

.mobile-show-inline-block,
.mobile-show-inline,
.mobile-show-flex,
.mobile-show {
  display: none;

  @include mobileLargeMax {
    display: block;
  }
}

.mobile-show-inline-block {
  @include mobileLargeMax {
    display: inline-block;
  }
}

.mobile-show-inline {
  @include mobileLargeMax {
    display: inline;
  }
}

.mobile-show-flex {
  @include mobileLargeMax {
    display: flex;
  }
}

.mobile-hidden {
  @include mobileLargeMax() {
    display: none;
  }
}

.display-block-mobile {
  @include mobileLargeMax {
    display: block;
  }
}

.stretched {
  @include stretched();
}

.centered {
  margin: 0 auto;
}

.no-overflow {
  overflow: hidden;
}

body.no-overflow,
html.no-overflow {
  // Targets iOS Devices.
  @supports (-webkit-overflow-scrolling: touch) {
    height: 100%;
  }
}

.caps {
  text-transform: uppercase;
}

.default-max-width {
  max-width: $containerMaxWidth;
}

.default-max-width-medium {
  max-width: $containerMediumMaxWidth;
}

.default-max-width-small {
  max-width: $containerSmallMaxWidth;
}

.default-max-width-text {
  max-width: $textMaxWidth;
}

.default-max-width-text-small {
  max-width: $textMaxWidthSmall;
}

// Default blocks.
.default-blocks {
  position: relative;
}

.default-font-sizes {
  font-family: $fontDefault;
  font-size: 18px;
  line-height: 1.278;
  font-weight: 400;

  &--very-big {
    font-size: 37px;
    line-height: 1.162;
  }

  &--big {
    font-size: 20px;
    line-height: 1.3;
  }

  &--medium {
    font-size: 18px;
    line-height: 1.278;
  }

  &--small {
    font-size: 14px;
    line-height: 1.357;
  }

  &--very-small {
    font-size: 13px;
    line-height: 1.357;
  }

  &--ultra-small {
    font-size: 11px;
    line-height: 1.357;
  }

  @include mobileLargeMax {
    font-size: 14px;
    line-height: 1.3;

    &--very-big {
      font-size: 22px;
      line-height: 1.162;
    }

    &--big {
      font-size: 14px;
      line-height: 1.3;
    }

    &--small {
      font-size: 14px;
      line-height: 1.357;
    }

    &--very-small {
      font-size: 13px;
      line-height: 1.357;
    }

    &--ultra-small {
      font-size: 11px;
      line-height: 1.357;
    }
  }
}

.default-text {
  @extend .default-font-sizes;

  a {
    text-decoration: none;
    text-underline: none;
  }

  ol {
    list-style: decimal;
  }

  ul li {
    position: relative;
  }

  h1 {
    @extend .headline-first;
  }

  h2 {
    @extend .headline-second;
  }

  h3 {
    @extend .headline-third;
  }

  h4 {
    @extend .headline-fourth;
  }

  h5 {
    @extend .headline-fifth;
  }

  ul:not(:last-child),
  ol:not(:last-child) {
    margin-bottom: 15px;
  }
}

.default-text-spacing {
  h1,
  .headline-first,
  .headline-special {
    &:not(:last-child) {
      margin-bottom: 25px;
    }

    &:not(:first-child) {
      margin-top: 35px;
    }
  }

  h2,
  h3,
  .headline-second,
  .headline-third {
    &:not(:last-child) {
      margin-bottom: 25px;
    }

    &:not(:first-child) {
      margin-top: 25px;
    }
  }

  h4,
  h5,
  .headline-fourth,
  .headline-fifth {
    &:not(:last-child) {
      margin-bottom: 25px;
    }

    &:not(:first-child) {
      margin-top: 25px;
    }
  }

  p,
  ol,
  ul,
  .list-container {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.default-padding {
  padding-left: $default-padding;
  padding-right: $default-padding;

  @include mobileLargeMax {
    padding-left: $default-padding--mobile-large-max;
    padding-right: $default-padding--mobile-large-max;
  }

  @include mobileSmallMax {
    padding-left: $default-padding--mobile-small-max;
    padding-right: $default-padding--mobile-small-max;
  }
}

.underlined {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $colorText;
    transition: width $transitionDurationDefault ease;
  }

  &:hover::after {
    width: 0;
    left: auto;
    right: 0;
  }
}

.black {
  font-weight: 900;
}

.bold {
  font-weight: 700;
}

.medium {
  font-weight: 500;
}

.light {
  font-weight: 300;
}

.relative {
  position: relative;
}

.width-50 {
  width: 50%;
}

.width-100 {
  width: 100%;
}

.flex {
  display: flex;

  > * {
    flex: 0 1 auto;
  }

  &--jc-space-between {
    justify-content: space-between;
  }

  &--jc-space-around {
    justify-content: space-around;
  }

  &--jc-center {
    justify-content: center;
  }

  &--jc-start {
    justify-content: flex-start;
  }

  &--jc-end {
    justify-content: flex-end;
  }

  &--jc-flex-end {
    justify-content: flex-end;
  }

  &--d-column {
    flex-direction: column;
  }

  &--d-row {
    flex-direction: row;
  }

  &--ai-end {
    align-items: flex-end;
  }

  &--ai-start {
    align-items: start;
  }

  &--ai-center {
    align-items: center;
  }

  &--wrap {
    flex-wrap: wrap;
  }

  &--tablet-wrap {
    @include tabletMax() {
      flex-wrap: wrap;
    }
  }

  &--mobile-wrap {
    @include mobileLargeMax() {
      flex-wrap: wrap;
    }
  }

  &--nowrap {
    flex-wrap: nowrap;
  }

  &--grow-1 {
    flex-grow: 1;
  }

  &--grow-0 {
    flex-grow: 0;
  }

  &--shrink-1 {
    flex-shrink: 1;
  }

  &--shrink-0 {
    flex-shrink: 0;
  }

  &--basis-auto {
    flex-basis: auto;
  }

  &--basis-half {
    flex-basis: 50%;
  }

  &--as-end {
    align-self: flex-end;
  }

  &--as-start {
    align-self: flex-start;
  }

  &--as-center {
    align-self: center;
  }

  &--gap-5 {
    gap: 5px;
  }
}

.margin {
  &--bottom-xxs {
    margin-bottom: 5px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &--bottom-xs {
    margin-bottom: 12px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &--bottom-s {
    margin-bottom: 15px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &--bottom-m {
    margin-bottom: 24px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    @include tabletMax {
      &--tablet-max {
        margin-bottom: 24px;
      }
    }
  }

  &--bottom-l {
    margin-bottom: 30px;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &--bottom-xl {
    margin-bottom: 40px;

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  &--bottom-xxl {
    margin-bottom: 50px;

    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }

  &--bottom-xxxl {
    margin-bottom: 120px;

    &:not(:last-child) {
      margin-bottom: 120px;
    }
  }

  &--top-xxs {
    margin-top: 5px;

    &:not(:last-child) {
      margin-top: 5px;
    }
  }

  &--top-xs {
    margin-top: 12px;

    &:not(:first-child) {
      margin-top: 12px;
    }
  }

  &--top-s {
    margin-top: 15px;

    &:not(:first-child) {
      margin-top: 15px;
    }
  }

  &--top-m {
    margin-top: 24px;

    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  &--top-l {
    margin-top: 30px;

    &:not(:first-child) {
      margin-top: 30px;
    }
  }

  &--top-xl {
    margin-top: 40px;

    &:not(:first-child) {
      margin-top: 40px;
    }
  }

  &--top-xxl {
    margin-top: 50px;

    &:not(:first-child) {
      margin-top: 50px;
    }
  }

  &--top-xxxl {
    margin-top: 120px;

    &:not(:first-child) {
      margin-top: 120px;
    }
  }

  &--left-s {
    margin-left: 14px;
  }

  &--left-m {
    margin-left: 24px;
  }


  &--side-xxxl {
    margin-left: 120px;
    margin-right: 120px;
  }
}

.remove-margin-bottom {
  margin-bottom: 0 !important;
}

.remove-padding-right {
  padding-right: 0 !important;
}

.spacer {
  margin: 24px 0 !important;
  visibility: hidden !important;
  opacity: 0 !important;

  &--xs {
    margin: 12px 0 !important;
  }

  &--s {
    margin: 15px 0 !important;
  }

  &--l {
    margin: 30px 0 !important;
  }

  &--xl {
    margin: 40px 0 !important;
  }

  &--xxl {
    margin: 50px 0 !important;
  }

  &--xxxl {
    margin: 120px 0 !important;
  }
}

.cover {
  position: relative;
}

.cover-link::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.slashed {
  text-decoration: line-through;
}

.bright-text {
  color: $colorBrightText;
}

.image img {
  height: auto;
}

.cursor-pointer {
  cursor: pointer;
}
