.brand-image {
  display: flex;
  align-items: center;
  height: 120px;

  img {
    flex: 0 0 auto;
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    transform: scale(1);
    transition: transform $transitionDurationDefault;

    a:hover & {
      transition-duration: 0.2s;
      transform: scale(1.2);
    }
  }
}
