.footer-main-info {
  &:not(:last-child) {
    margin-bottom: 120px;

    @include tabletMax {
      margin-bottom: 80px;
    }

    @include mobileLargeMax {
      margin-bottom: 60px;
    }
  }

  @include mobileLargeMax {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mobileSmallMax {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.footer-main-info-text {
  &:not(:last-child) {
    margin-bottom: 50px;
  }
}

.footer-main-info-title {
  &:not(:last-child) {
    margin-bottom: 17px;
  }
}
