/* =================================================
        Base definitions.
================================================= */

html {
  box-sizing: border-box;
  font-family: $fontDefault;
  scroll-behavior: smooth;
}
body {
  padding: 12px;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

*,
*::before,
*::after {
  box-sizing: inherit;
  flex: 0 1 auto;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba($colorBlack, 0);
  outline: none;
}

img,
picture {
  display: block;
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

a,
button {
  cursor: pointer;
}

b,
strong {
  font-weight: 600;
}

em {
  font-style: italic;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

body {
  color: $colorText;
  min-height: 100vh;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

div {
  display: block;
}

.no-scroll {
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px;
  }
}


