.header-nav {
  display: flex;
  align-items: flex-start;

  @include tabletMax {
    position: fixed;
    display: flex;
    align-items: stretch;
    bottom: 0;
    left: 0;
    z-index: 1001;
    height: 70px;
    width: 100%;
    border-top: 1px solid $colorBrightText;
    background-color: $colorMineShaft;
  }
}

.header-nav-item {
  text-align: center;
  transition: color $transitionDurationDefault ease;
  flex-grow: 1;

  &:not(:last-child) {
    margin-right: 30px;
  }

  > i {
    display: block;
    font-size: 26px;
  }

  &:hover .header-dropdown-label {
    color: $colorActiveState;
  }

  @include tabletMax {
    color: $colorBrightText;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      margin-bottom: 0;
    }

    &:not(:last-child) {
      margin-right: 0;
      border-right: 1px solid $colorBrightText;
    }
  }
}

.footer {
  @include tabletMax {
    padding-bottom: 48px; // Needed for mobile Navigation.
  }
}

.header-nav-item-text {
  margin-top: 5px;
  max-height: 50px;
  overflow: hidden;
  transition: max-height $transitionDurationDefault ease, margin $transitionDurationDefault ease;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  i {
    font-size: 10px;
  }

  .header.sticky & {
    max-height: 0;
    margin: 0;
  }

  @include tabletMax {
    display: none;
  }
}

.header-nav-item-text-icon {
  transform: rotate(90deg);
  margin-top: 2px;

  &--last {
    transform: rotate(-90deg);
  }
}

.listname-header-nav {
  display: inline-block;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
