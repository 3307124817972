.footer-info-item {
  &:not(:last-child) {
    margin-bottom: 70px;
  }
}

.footer-info-item-title {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.footer-info-item-title-link {
  color: $colorSilverChalice;
  transition: opacity $transitionDurationDefault ease;

  &:not(:first-child) {
    margin-left: 15px;
  }

  &:hover {
    opacity: 0.6;
  }
}
