.listing-filters-title {
  display: none;
  width: 100%;
  padding: 15px 10px;
  border-bottom: 1px solid rgba($colorBlack, 0.1);

  @include tabletMax {
    display: flex;
  }
}

.listing-filters-title-count {
  display: inline-block;
  vertical-align: middle;
  color: $colorBrightText;
  height: 29px;
  width: 29px;
  text-align: center;
  line-height: 29px;
  background: $colorActiveState;
  border-radius: 50%;
  margin-left: 5px;
  flex-grow: 0;
  flex-shrink: 0;
}

.listing-filters-title-filters {
  width: 100%;
  padding-top: 1px;
  margin-left: 17px;
}
