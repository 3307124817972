/* =================================================
        SCSS Functions.
================================================= */

/* stylelint-disable */
///////////////////////////////////////////////////////////
// Plain SASS Trigonometry Algorithm in Taylor Expansion //
//                                                       //
// Based on                                              //
//      http://japborst.net/posts/sass-sines-and-cosines //
///////////////////////////////////////////////////////////
@use 'sass:math';

$pi: 3.14159265359;
$_precision: 10;

@function pow($base, $exp) {
  $value: $base;

  @if $exp > 1 {
    @for $i from 2 through $exp {
      $value: $value * $base;
    }
  }

  @if $exp < 1 {
    @for $i from 0 through -$exp {
      $value: math.div($value, $base);
    }
  }

  @return $value;
}

@function fact($num) {
  $fact: 1;

  @if $num > 0 {
    @for $i from 1 through $num {
      $fact: $fact * $i;
    }
  }

  @return $fact;
}

@function _to_unitless_rad($angle) {
  @if unit($angle) == 'deg' {
    $angle: math.div($angle, 180deg) * $pi;
  }

  @if unit($angle) == 'rad' {
    $angle: math.div($angle, 1rad);
  }

  @return $angle;
}

@function sin($angle) {
  $a: _to_unitless_rad($angle);
  $sin: $a;

  @for $n from 1 through $_precision {
    $sin: $sin + math.div(pow(-1, $n), fact(2 * $n + 1)) * pow($a, (2 * $n + 1));
  }

  @return $sin;
}

@function cos($angle) {
  $a: _to_unitless_rad($angle);
  $cos: 1;

  @for $n from 1 through $_precision {
    $cos: $cos + math.div(pow(-1, $n), fact(2 * $n)) * pow($a, 2 * $n);
  }

  @return $cos;
}

@function tan($angle) {
  @return math.div(sin($angle), cos($angle));
}
/* stylelint-enable */
