.table-accordion-content {
  &--grey {
    background-color: $colorGallery;
  }
}

.table-accordion-popup td > div {
  display: none;
}

.table-accordion-header-navigation {
  position: relative;

  &::before,
  &::after {
    content: '\e908';
    position: absolute;
    right: calc(100% - 5px);
    font-family: $fontIcons;
    font-size: 10px;
    line-height: 1;
  }

  &::before {
    top: 8px;
    transform: rotate(180deg);
  }

  &::after {
    bottom: 8px;
  }
}

.table-accordion-header-toggler {
  cursor: pointer;
  transition: color $transitionDurationDefault ease;

  .table-row:hover & {
    color: $colorActiveState;
  }

  i {
    display: inline-block;
    transition: transform $transitionDurationDefault ease;

    .table-row.active & {
      transform: rotate(90deg);
    }
  }
}

.table-accordion-wishlist {
  padding: 8px;
  transition: color $transitionDurationDefault ease;

  &:hover {
    color: $colorActiveState;
  }
}

.table-accordion-cart {
  padding: 8px;
  transition: color $transitionDurationDefault ease, background-color $transitionDurationDefault ease;

  &:hover {
    color: $colorBrightText;
    background-color: $colorActiveState;
  }
}
