@use 'sass:math';

$layout-line-split--gutter: 60px !default;

.layout-line-split {
  display: flex;
  margin: #{148px - $layout-line-split--gutter} #{math.div($layout-line-split--gutter, -2)} 40px #{math.div(
      $layout-line-split--gutter,
      -2
    )};

  &:after {
    content: '';
    display: block;
    flex: 0 0 auto;
    order: 2;
    width: 1px;
    background-color: $colorScorpion;
    margin: -30px auto;

    @include tabletMax {
      display: none;
    }
  }

  @include tabletMax {
    display: block;
  }
}

.layout-line-split-left,
.layout-line-split-right {
  flex-direction: column;
  flex: 0 1 auto;
  display: flex;
  padding: ($layout-line-split--gutter * 0.5);

  .icon-information {
    margin-left: 1em;
  }
}

.layout-line-split-left {
  order: 1;
  flex-basis: 400px + $layout-line-split--gutter;

  .headline-first {
    margin-right: -2em;

    @include desktopMax {
      margin-right: 0;
    }
  }

  > form {
    display: flex;
    flex-direction: column;
  }
}

.layout-line-split-right {
  order: 3;
  flex-basis: 570px + $layout-line-split--gutter;
}

.layout-line-split-push-top {
  margin-top: auto;
}

.layout-line-split-inset {
  width: 400px;
  max-width: 100%;
}
