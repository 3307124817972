.password {
  flex-wrap: wrap;
}

.password-save {
  @include mobileLargeMax {
    width: 100%;
    text-align: center;

    &:not(:first-child) {
      margin-top: 15px;
    }

    .btn {
      max-width: 100%;
      text-align: center;
      justify-content: center;
    }
  }
}
