.header-detail {
  &:not(:last-child) {
    margin-bottom: 140px;
  }

  @include tabletMax {
    &:not(:last-child) {
      margin-bottom: 80px;
    }
  }

  @include mobileLargeMax() {
    margin-top: 0;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

.header-detail-top:not(:last-child) {
  @include tabletMax {
    margin-bottom: 60px;
  }

  @include tabletSmallMax {
    margin-bottom: 50px;
  }
}

.header-detail-title {
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @include mobileLargeMax {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.header-detail-slider {
  position: relative;
  width: calc(30% - 60px);
  margin-right: 60px;

  @include desktopSmallMax {
    width: calc(50% - 30px);
    margin-right: 30px;
  }

  @include tabletMax() {
    width: calc(100% + 40px);
    margin-right: -20px;
    margin-bottom: 60px;
    margin-left: -20px;
  }
}

.header-detail-slider-pagination {
  position: absolute;
  top: calc(100% + 22px);
  width: 100%;
}

.header-detail-infos {
  width: calc(70% - 60px);
  margin-left: 60px;

  @include desktopSmallMax {
    width: calc(50% - 30px);
    margin-left: 30px;
  }

  @include tabletMax() {
    width: 100%;
    margin-left: 0;
  }
}

.header-detail-icons {
  margin-bottom: -12px;

  .label,
  button {
    margin-right: 12px;
    margin-bottom: 12px;
    transition: color $transitionDurationDefault ease;

    &:last-child {
      margin-right: 0;
    }
  }

  button:hover {
    color: $colorActiveState;
  }
}

.header-detail-rating {
  padding-bottom: 1px;

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.brand-label {
  float: left;
  margin-left: 40px;
}

@media only screen and (max-width: 1024px) {
  .header-detail-slider {
    width: calc(100% + 10px);
  }
  .header-detail-slider {
    margin-left: -5px;
  }
}
