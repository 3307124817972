.popup-tile {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 500px;
  max-width: 100%;
  padding: 66px 48px 38px;
  border-radius: 4px;
  background-color: $colorBrightText;

  &--bg-image {
    background-size: cover;
    background-position: 50% 50%;
  }

  label .icon-information {
    display: inline-block;
    margin-left: 1em;
    vertical-align: middle;
  }
}

.popup-tile-logo {
  width: 190px;
  max-width: 100%;
  margin-right: auto;
  margin-bottom: 70px;
  margin-left: auto;
}

.popup-tile-close {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 17px;
  margin-right: 15px;
  cursor: pointer;
  transition: transform $transitionDurationDefault ease, color $transitionDurationDefault ease;

  &:hover {
    color: $colorActiveState;
    transform: scale(1.2);
  }
}

.popup-tile-push-top {
  margin-top: auto;
}

.popup-tile-push-bottom {
  margin-bottom: auto;
}
