.news-slider-item {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: $colorGallery;
  transition: box-shadow $transitionDurationDefault ease;

  &:hover {
    box-shadow: 0 1px 10px 0 rgba($colorBlack, 0.35);
  }

  @include tabletSmallMax {
    &:hover {
      box-shadow: 0 1px 6px 0 rgba($colorBlack, 0.35);
    }
  }
}

.news-slider-item-image {
  width: 100%;
  height: 0;
  padding-bottom: 95.8%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.news-slider-item-bottom {
  position: relative;
  padding: 12px 10px 45px;
  flex-grow: 1;
  flex-shrink: 0;
}

.news-slider-item-date {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.news-slider-item-title {
  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.news-slider-item-button {
  position: absolute;
  bottom: 12px;
  left: 10px;
}
