$footerInfoItemFullImageWidth: 135px;

.footer-info-items-full {
  width: 100%;
  margin-bottom: -40px;
}

.footer-info-item-full {
  width: 100%;
  margin-bottom: 40px;
}

.footer-info-item-full-image {
  width: $footerInfoItemFullImageWidth;
  flex-grow: 0;
  flex-shrink: 0;

  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    flex: 1 1 0; // IE bugfix.
  }
}

.footer-info-item-full-text {
  width: calc(100% - #{$footerInfoItemFullImageWidth});
  padding-left: 25px;
  max-width: 400px;

  > div:not(:last-child) {
    margin-bottom: 4px;
  }
}

.footer-info-item-full-link {
  color: $colorSilverChalice;
}

a.footer-info-item-full {
  transition: opacity $transitionDurationDefault ease;

  &:hover {
    opacity: 0.6;
  }
}
