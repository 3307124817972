/* =================================================
        Additional Styling for Swiper.
================================================= */

:root {
  --swiper-theme-color: $colorCeruleanBlue !important;
}


.swiper-container {
  // Allows Shadows to be visible even though we have overflow hidden.
  padding: 10px;
  margin-left: -10px;
  width: calc(100% + 20px);
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  padding: 9px;
  z-index: 2;
  font-size: 20px;
  bottom: 24px;
  opacity: 0.84;
  transform: translateY(-50%);
  background: $colorWhite;
  cursor: pointer;
  transition: color $transitionDurationDefault ease, background $transitionDurationDefault ease;

  &:hover {
    color: $colorWhite;
    background-color: $colorActiveState;
    transition-duration: $transitionDurationDefault;
  }
}

.swiper-wrapper {
  height: auto;
}

.swiper-slide {
  box-sizing: border-box;
  height: auto;
  display: flex;
}

.swiper-slide-content {
  width: 100%;
  height: 0;
  padding-bottom: 80%;
  background-size: cover;
  background-position: center;
}

.swiper-button-disabled {
  opacity: 0.7;
  pointer-events: none;
}

.swiper-button-prev {
  right: 85px;
  transform: translateY(-50%) rotateY(180deg);
}

.swiper-button-next {
  right: 15px;
}

.swiper-arrow {
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
  cursor: pointer;
  transition: color $transitionDurationDefault ease;

  &:hover {
    color: $colorJaffa;
  }

  @include desktopMax {
    top: auto;
    bottom: 0;
    transform: none;
  }

  [class*='icon'] {
    display: inline-block;
    font-size: 50px;
  }

  &--next {
    right: 0;
    margin-right: 117px;

    @include desktopMax {
      margin-right: 40px;
    }
  }

  &--prev {
    left: 0;
    margin-left: 117px;

    @include desktopMax {
      margin-left: 40px;
    }

    [class*='icon'] {
      transform: rotate(180deg);
    }
  }
}

.swiper-arrow.swiper-button-disabled {
  opacity: 0.4;
  pointer-events: none;
}

.swiper-control-wrapper {
  position: relative;
  display: flex;
  align-items: center;

  .swiper-arrow {
    position: static;
    margin: 0;
    transform: none;

    [class*='icon'] {
      font-size: 20px;
    }
  }
}

.search-button {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px;
  font-size: 20px;
  background: $colorBrightText;
  z-index: 2;
  opacity: 0.84;
  transition: color $transitionDurationDefault ease, background-color $transitionDurationDefault ease;

  &:hover {
    background-color: $colorJaffa;
    color: $colorBrightText;
  }
}

.swiper-container-thumbs {
  box-sizing: border-box;
  padding-top: 22px;

  .swiper-slide {
    cursor: pointer;
    transition: opacity $transitionDurationDefault ease;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0;
      background: $colorJaffa;
      transition: all $transitionDurationDefault ease;
    }

    &:hover {
      opacity: 0.85;
    }
  }

  .swiper-slide-thumb-active {
    &::after {
      height: 4px;
    }
  }
}

.swiper-pagination {
  margin: 0 auto;
  text-align: center;

  &--on-bg {
    margin-top: 60px;
    margin-bottom: -60px;
  }
}

.swiper-pagination-bullet {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: 4px;
  left: auto !important;
  right: auto !important;
  transition: all $transitionDurationDefault ease;
  opacity: 0.2;
  //border-radius: 50%;
  border: 1px solid transparent;

  &:only-child {
    display: none;
  }

  &::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    left: 50%;
    //border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: $colorText;
    transition: background-color $transitionDurationDefault ease;
  }

  &:hover::after {
    background-color: $colorJaffa;
  }

  .swiper-pagination--bright & {
    &::after {
      background-color: $colorBrightText;
    }

    &:hover::after {
      background-color: $colorJaffa;
    }
  }
}

.swiper-pagination-bullet-active {
  opacity: 1;
  border-color: rgba($colorText, 0.4);
  width: 20px;
  height: 20px;

  &:hover {
    border-color: rgba($colorJaffa, 0.4);
  }

  .swiper-pagination--bright & {
    border-color: rgba($colorBrightText, 0.4);

    &:hover {
      border-color: rgba($colorJaffa, 0.4);
    }
  }
}

.swiper-pagination-bullet-active-next,
.swiper-pagination-bullet-active-prev {
  opacity: 0.6;
}

.swiper-pagination-bullet-active-next-next,
.swiper-pagination-bullet-active-prev-prev {
  opacity: 0.4;
}

.img-brand-slider-container {
  margin: 0 auto;
}

.img-brand-slider {
  max-height: 50px;
}

[data-slider-config='brands'] {
  img {
    height: auto;
  }
}

.header-detail {
  .swiper-button-disabled {
    display: none;
  }
}

.product-slider {
  .swiper-slide-content {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}



app-image-slider {
  .swiper-button-next:after, .swiper-button-prev:after {
    display: none;
  }
  .swiper-button-prev, .swiper-button-next {
    color: #00644b;
  }
  .swiper-button-prev:hover, .swiper-button-next:hover {
    color: #ffffff;
  }
}

