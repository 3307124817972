.contact-disturber {
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: $colorGallery;

  a {
    transition: color $transitionDurationDefault ease;

    &:hover {
      color: $colorActiveState;
    }
  }

  @include mobileLargeMax {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.contact-disturber-content {
  @include tabletSmallMax {
    flex-direction: column;
    text-align: center;
  }
}

.contact-disturber-title {
  &:not(:last-child) {
    margin-bottom: 2px;
  }

  @include tabletSmallMax {
    display: none;
  }
}

.contact-disturber-title-mobile {
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @include tabletSmallMin {
    display: none;
  }
}

.contact-disturber-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-shrink: 0;
  flex-grow: 0;

  &:not(:last-child) {
    margin-right: 50px;
  }

  @include tabletSmallMax {
    width: 168px;
    height: 168px;

    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
}

.contact-disturber-top {
  &:not(:last-child) {
    margin-bottom: 27px;

    @include tabletSmallMax {
      margin-bottom: 4px;
    }
  }
}

.contact-disturber-name {
  &:not(:last-child) {
    margin-bottom: 6px;
  }
}
