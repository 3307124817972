.label {
  display: inline-block;
  vertical-align: middle;
  padding: 8px 11px 6px;
  transition: color $transitionDurationDefault ease;

  &--active {
    color: $colorBrightText;
    background-color: $colorActiveState;
  }

  &--error {
    color: $colorBrightText;
    background-color: $colorMonza;
  }

  &--info {
    color: $colorBrightText;
    background-color: $colorGlacier;
  }

  &--warn {
    color: $colorBrightText;
    background-color: $colorJaffa;
  }
}
