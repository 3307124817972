.back-to-top {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $colorText;
  transition: color 350ms ease;

  &:hover {
    color: $colorActiveState;
  }

  @include tabletSmallMax {
    position: fixed;
    bottom: 60px;
    right: 20px;
    z-index: $zFooter + 10;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $colorActiveState;

    span {
      display: none;
    }
  }

  @include mobileLargeMax {
    width: 30px;
    height: 30px;
  }
}

.back-to-top-icon {
  display: inline-block;
  font-size: 20px;
  transform: rotate(-90deg);
  margin-right: 5px;

  @include tabletSmallMax {
    display: none;
  }
}

.back-to-top-icon-mobile {
  display: none;
  line-height: 47px;
  padding-top: 3px;
  width: 100%;
  text-align: center;
  font-family: $fontIcons;
  font-size: 16px;
  color: $colorBrightText;
  transform: rotate(180deg);
  transition: transform $transitionDurationDefault ease;

  @include tabletSmallMax {
    display: block;

    .back-to-top:hover & {
      transform: rotate(180deg) scale(1.2);
    }
  }

  @include mobileLargeMax {
    font-size: 10px;
    padding: 2px 0 0;
    line-height: 28px;
  }
}
