$default-tile--padding-top: 38px !default;
$default-tile--padding-right: 23px !default;
$default-tile--padding-bottom: 38px !default;
$default-tile--padding-left: 32px !default;

.default-tile {
  display: flex;
  flex-direction: column;
  padding: $default-tile--padding-top $default-tile--padding-right $default-tile--padding-bottom
    $default-tile--padding-left;
  border: 1px solid $colorText;
  margin-bottom: $gutter;
  flex: 1 1 100%;
}

.default-tile-image {
  flex: 0 0 auto;
  margin: #{$default-tile--padding-top * -1} #{$default-tile--padding-right * -1} 20px #{$default-tile--padding-left *
    -1};

  img {
    height: auto;
    max-height: 250px;
    object-fit: cover;
    min-height: 250px;
  }
}

.default-tile-main {
  margin-bottom: 30px;
}

.default-tile-push {
  margin-top: auto;
}
