@import '../../../components/product/card-product';

.card-product-description-bottom-cart-button {
  .touchevents &,
  .card-product:hover & {
    color: $colorBrightText;
    background-color: $colorTempUnavailable;
  }

  .card-product:hover &:hover,
  &:hover {
    background-color: rgba($colorTempUnavailable, 0.9);
  }
}

.card-product-top-buttons {
  button {
    &:hover {
      background-color: $colorTempUnavailable;
      color: $colorBrightText;
    }
  }
}

.truncated {
  height: 100px;
  overflow: hidden;
}

.show-less-btn,
.ngx-ellipsis-more {
  cursor: pointer;
  display: block;
  text-decoration: underline;
  margin-top: 10px;
}


.card-product-top-image {
  left: 5px;
  top: 0;
  width: calc(100% - 10px);
  height: calc(100% - 20px);
}

.card-product-top-image img {
  max-height: 290px;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.card-product-top-buttons {
  background: white;
  position: absolute;
  top: 0px;
  right: 0;
  cursor: pointer;
  font-size: 20px;
  padding: 15px;
}

.card-product-description-bottom {
  display: block;
}

.card-product-description-bottom-cart {
  display: block;
}

.card-product-description-bottom-cart-button {
  margin: 0 auto;
  height: 37px;
}

.unit-selector {
  margin-right: 25px;
}

@media screen and (max-width: 1024px){
  .article_availability {
    padding: 10px;
  }
  .card-product-description-bottom {
    padding: 10px;
  }
  .card-product-top-buttons {
    top: -313px;
    right: -20px;
  }
}
