@use 'sass:math';

$layout-split--gutter: $gutter !default;

.layout-split {
  //overflow: hidden;
}

.layout-split-container {
  display: flex;
  margin-right: math.div($layout-split--gutter, -2);
  margin-left: math.div($layout-split--gutter, -2);

  .default-padding {
    padding: 0;
  }

  .default-max-width {
    max-width: none;
  }

  @include tabletMax {
    display: block;
  }
}

.layout-split-main,
.layout-split-aside {
  flex: 1 1 auto;
  padding: $layout-split--gutter * 0.5;
}

.layout-split-main {
  flex-basis: 940px;
}

.layout-split-aside {
  order: -1;
  flex-basis: 298px;

  @include tabletMax {
    display: none;
  }
}
