.icon-wrapper {
  position: relative;
}

.badge {
  background: $colorActiveState;
  position: absolute;
  top: 2px;
  right: -8px;
  vertical-align: middle;
  border-radius: 50%;
  font-size: 13px;
  line-height: 1.6;
  color: $colorWhite;
  display: inline-block;
  font-weight: 400;
  font-family: $fontRoboto;
  height: 20px;
  width: 20px;
}

.header-dropdown:hover .badge {
  box-shadow: 0 0 5px $colorWhite;
}
