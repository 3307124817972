.product-small {
  display: flex;
  margin-bottom: 10px;

  strong {
    font-weight: bolder;
  }

  > * + * {
    margin-left: 10px;
  }
}

.product-small-header,
.product-small-main,
.product-small-footer {
  flex: 1 1 auto;
}

.product-small-header {
  flex-basis: 70px;

  img {
    width: 100%;
  }
}

.product-small-main {
  flex-basis: 210px;
}

.product-small-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-basis: 95px;

  [class*='icon-'] {
    font-size: 16px;
  }
}

.product-small-footer-quantity {
  flex: 0 0 60px;
  text-align: center;
}

.product-small-footer-trash {
  flex: 0 0 auto;
  font-size: 18px;
}

.product-small-footer-price {
  flex: 0 1 auto;
  margin-top: 6px;
  margin-left: auto;
}
