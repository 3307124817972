.login-logo {
  width: 190px;
  max-width: 100%;
  margin-right: auto;
  margin-bottom: 70px;
  margin-left: auto;
}

.login-push-top {
  margin-top: auto;
}
