@import '../../components/mini-cart';

.mini-cart-item {
  .btn {
    background-color: $colorScorpion;
    border-color: $colorScorpion;

    &:hover {
      background-color: $colorTempUnavailable;
      border-color: $colorTempUnavailable;
    }
  }
}

.mini-cart-item.linked:hover {
  background: $colorGallery;
}

.product-small-header .top-list-image {
  height: 100%;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.header-dropdown .product-small-footer-trash {
  margin-left: 10px;
}
