.progress-bar,
.progress-bar-current {
  border-radius: 10px / 50%;
}

.progress-bar {
  overflow: hidden;
  position: relative;
  text-align: right;
  color: $colorBlack;
  background-color: $colorGallery;
}

.progress-bar-total,
.progress-bar-current {
  padding: 2px 10px 0;
}

.progress-bar-current {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $colorWhite;
  background-color: $colorActiveState;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;

  white-space: pre;
  display: inline;
  left: 50%;
}
