$footerInfoItemRoundedHeight: 106px;

.footer-info-items-rounded {
  width: calc(100% + 12px);
  margin-left: -12px;
  margin-bottom: -40px;
}

.footer-info-item-rounded {
  width: calc(33.33% - 12px);
  height: $footerInfoItemRoundedHeight;
  padding: 15px;
  margin-left: 12px;
  margin-bottom: 40px;
  background-color: $colorBrightText;
  border-radius: $footerInfoItemRoundedHeight * 0.5;

  img {
    max-height: 100%;
    max-width: 100%;
    flex: 0 0 auto;
  }

  @include desktopSmallMax {
    width: calc(50% - 12px);
  }

  @include tabletMax {
    width: calc(33.33% - 12px);
  }

  @include mobileLargeMax {
    width: calc(50% - 12px);
  }
}

a.footer-info-item-rounded {
  transition: opacity $transitionDurationDefault ease;

  &:hover {
    opacity: 0.6;
  }
}
