.technical-table {
  width: 100%;
  margin-bottom: 20px;

  table {
    width: 100%;
  }

  tr:first-child td {
    border-top: 1px solid $colorGallery;
  }

  td {
    vertical-align: middle;
    width: 50%;
    padding: 5px 0;
    border-bottom: 1px solid $colorGallery;

    img {
      max-height: 24px;
    }
  }
}
