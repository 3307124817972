.header-search-result {
  display: none;
  position: absolute;
  top: calc(100% + 7px);
  left: 0;
  width: 100%;
  background-color: $colorBrightText;
  -webkit-box-shadow: 0 2px 7px 0 rgba($colorBlack, 0.35);
  -moz-box-shadow: 0 2px 7px 0 rgba($colorBlack, 0.35);
  box-shadow: 0 2px 7px 0 rgba($colorBlack, 0.35);
  z-index: 1;
  overflow: auto;
  max-height: calc(100vh - (155px + 60px)); // 155px is offset to top and 60px is offset to bottom.

  .header-search.open & {
    display: block;
  }

  @include tabletMax {
    left: auto;
    right: 0;
    width: calc(100vw - 80px);
    max-height: calc(
      100vh - (105px + 48px + 40px)
    ); // 105px is offset to top, 48px is height of bottom bar and 40px is offset to bottom.
  }

  @include tabletSmallMax {
    max-height: calc(
      100vh - (95px + 48px + 40px)
    ); // 95px is offset to top, 48px is height of bottom bar and 40px is offset to bottom.
  }

  @include mobileLargeMax {
    width: calc(100vw - 40px);
    max-height: calc(
      100vh - (87px + 48px + 20px)
    ); // 87px is offset to top, 48px is height of bottom bar and 20px is offset to bottom.
  }

  @include mobileSmallMax {
    width: calc(100vw - 20px);
    max-height: calc(
      100vh - (87px + 48px + 10px)
    ); // 87px is offset to top, 48px is height of bottom bar and 10px is offset to bottom.
  }
}
