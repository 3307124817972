@use 'sass:math';

$inline-list--distance: 20px;
$inline-list--small-gutter--distance: 8px;
$inline-list--edit-link-gutter--distance: 30px;

.inline-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 math.div($inline-list--distance, -2);
  align-items: flex-start;

  &--reset-margin {
    margin-top: math.div($inline-list--distance, -2);
    margin-bottom: math.div($inline-list--distance, -2);
  }

  &--reset-top {
    margin-top: math.div($inline-list--distance, -2);
  }

  &--reset-top-small-gutter {
    margin-top: math.div($inline-list--small-gutter--distance, -2);
  }

  &--justify {
    justify-content: center;
  }

  &--valign-center {
    align-items: center;
  }

  &--small-gutter {
    margin-right: math.div($inline-list--small-gutter--distance, -2);
    margin-left: math.div($inline-list--small-gutter--distance, -2);
  }

  &--small-gutter &-item {
    margin: $inline-list--small-gutter--distance * 0.5;
  }

  &--edit-link-gutter {
    margin-right: math.div($inline-list--edit-link-gutter--distance, -2);
    margin-left: math.div($inline-list--edit-link-gutter--distance, -2);

    @include tabletMax {
      display: block;
    }
  }

  &--edit-link-gutter &-item {
    margin: $inline-list--edit-link-gutter--distance * 0.5;
  }
}

.inline-list-item {
  margin: $inline-list--distance * 0.5;
}
