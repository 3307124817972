.listing-filters-item {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 34px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: rgba($colorBlack, 0.1);
  }

  @include tabletMax {
    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
}

.listing-filters-item-button {
  position: relative;
  width: 100%;
  padding: 7px 40px 6px 0;
  text-align: left;
  font-weight: 700;
  transition: color $transitionDurationDefault ease;

  &:hover,
  .selected &,
  .open &,
  .active & {
    color: $colorActiveState;

    @include tabletMax {
      color: $colorText;
    }
  }

  @include tabletMax {
    font-weight: 400;
  }
}

.listing-filters-item-mobile-active {
  padding: 2px 5px 1px;
  background-color: $colorActiveState;
  color: $colorBrightText;

  @include tabletMin {
    display: none;
  }
}

.listing-filters-item-button-plus {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 30px;

  > div {
    position: absolute;
    top: 50%;
    left: 7px;
    width: 17px;
    height: 1px;
    background-color: $colorText;
    transition: transform $transitionDurationDefault ease;

    &:nth-child(2) {
      transform: rotate(90deg);

      @include tabletMin {
        .open & {
          transform: none;
        }
      }
    }
  }
}

.listing-filters-item-options-outer {
  width: 100%;
}

.listing-filters-item-options {
  max-height: 0;
  width: calc(100% + 20px);
  padding: 0 10px;
  margin-left: -10px;
  overflow: hidden;
  transition: max-height $transitionDurationDefault ease, padding $transitionDurationDefault ease;

  .listing-filters-item.open & {
    max-height: 800px;
    padding: 14px 10px 26px;
  }

  @include tabletMax {
    position: fixed;
    display: flex;
    flex-direction: column;
    left: 100%;
    top: 0;
    width: 100%;
    height: 100%;
    max-height: none;
    padding: 0;
    margin: 0;
    background-color: $colorWhite;
    transition: left $transitionDurationDefault ease;
    z-index: 2;

    /* stylelint-disable */
    .listing-filters.open .listing-filters-item.open & {
      max-height: none;
      padding: 0;
      left: 0;
    }
    /* stylelint-enable */
  }
}

.listing-filters-item-options-inner {
  @include tabletMax {
    height: 100%;
    overflow: auto;
    padding: 15px 10px 60px;
  }
}

.listing-filters-item-option {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.listing-filters-item-reset {
  button {
    padding-left: 10px;
    transition: color $transitionDurationDefault ease;

    &:hover {
      color: $colorActiveState;
    }

    &:not(:last-child) {
      padding-right: 10px;
      border-right: 1px solid black;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @include tabletMax {
    display: none;
  }
}

.header-detail-infos {
  .listing-filters-item {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  .listing-filters-item-button {
    padding: 15px 5px;
    &:hover {
      background-color: #eee;
      transition: background-color $transitionDurationDefault ease;
    }
  }
}

app-product-article-list {
  .listing-filters-item {
    width: 24%;
    float: left;
    margin-right: 1%;
    @include tabletMax {
      width: 100%;
    }
  }
  .listing-filters-item:not(:last-child) {
    margin-bottom: 10px;
    margin-right: 10px;
  }

  .listing-filters-item.open {
    //max-height: 250px;
    //overflow-y: overlay;
    //overflow-x: hidden;
  }
  .listing-filters-item-options {
    margin-left: 0;
    width: 100%;
  }
  .listing-filters-item::after {
    background-color: transparent;
  }

  .listing-filters-item-button {
    background-color: #f0f0f0;
    color: #000000;
    font-weight: normal;
    padding: 15px;
  }
  .listing-filters-item-button-plus {
    display: none;
  }

  .listing-filters-item.open .listing-filters-item-options {
    border: 1px solid #afafaf;
  }

  .listing-filters-item-button:after {
    content: '\25BC';
    color: #afafaf;
    font-size: 12px;
  }
  .listing-filters-item.open {
    .listing-filters-item-button:after {
      content: '\25B2';
    }
  }
}
