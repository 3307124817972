.quantity-select {
  display: flex;
  height: 35px;
  transition: opacity $transitionDurationDefault ease;
  justify-content: center;

  input {
    font-size: 18px;
    width: 90px;
    height: 100%;
    appearance: none;
    border: none;
    outline: none;
    color: $colorText;
    text-align: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    @include mobileLargeMax {
      width: 100%;
    }

    &.bg-transparent {
      background-color: transparent;
    }
  }

  &--big {
    height: 45px;
    border: 1px solid $colorGallery;

    input {
      width: 85px;
    }

    .quantity-select-button {
      width: 45px;
      font-size: 18px;
    }
  }

  button {
    &.hidden {
      display: none;
    }
  }
}

.quantity-select-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 25px;
  font-size: 9px;
  background-color: $colorGallery;
  transition: background-color $transitionDurationDefault ease;

  &:hover {
    background-color: rgba($colorGallery, 0.5);
  }
}

.container-quantity {
  .quantity-select {
    height: 25px;
    margin-top: 20px;
  }
}

.card-product-description {
  .quantity-select input {
    width: 40px;
  }
}

.input-section {
  .quantity-select-button {
    width: 50px;
  }

  .quantity-select input {
    background-color: #393939;
    color: white;
    border: 1px solid white;
  }
}

