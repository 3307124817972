.tab-content-item {
  @include tabletMax {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.tab-content-item-inner {
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0;
  font-size: 0;
  transform: translateY(6px);
  pointer-events: none;
  transition: opacity $transitionDurationDefault, transform $transitionDurationDefault;

  .tab-content-item.active & {
    position: relative;
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
    transition-delay: $transitionDurationDefault;
  }

  .tab-content-item.fade-out & {
    opacity: 0;
    transform: translateY(6px);
    transition-delay: 0s;
  }

  .tab-content-item.is-relative & {
    position: relative;
  }

  @include tabletMin {
    display: block !important;
  }

  @include tabletMax {
    position: relative;
    display: none;
    opacity: 1;
    transform: none;
    overflow: hidden;
    padding: 10px 40px;

    .tab-content-item.active & {
      display: block;
    }
  }

  @include mobileLargeMax {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mobileSmallMax {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.tab-content-item-title {
  position: relative;
  background-color: $colorGallery;
  padding: 10px 40px;
  width: 100%;
  text-align: left;

  @include tabletMin {
    display: none;
  }

  @include mobileLargeMax {
    padding: 10px 20px;
  }

  @include mobileSmallMax {
    padding: 10px;
  }
}

.tab-content-item-plus {
  position: absolute;
  right: 35px;
  top: 0;
  height: 100%;
  width: 30px;

  > div {
    position: absolute;
    top: 50%;
    left: 7px;
    width: 17px;
    height: 1px;
    background-color: $colorText;
    transition: transform $transitionDurationDefault ease;

    &:nth-child(2) {
      transform: rotate(90deg);

      /* stylelint-disable */
      .tab-content-item.active & {
        transform: none;
      }
      /* stylelint-enable */
    }
  }

  @include mobileLargeMax {
    right: 15px;
  }

  @include mobileSmallMax {
    right: 5px;
  }
}
