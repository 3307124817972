.accordion-item {
  padding-top: 12px;
  padding-bottom: 6px;
  border-top: 1px solid $colorGallery;

  &--large {
    padding-top: 19px;
    padding-bottom: 15px;
    font-size: 24px;
    line-height: 1.4;
  }

  &:last-child {
    border-bottom: 1px solid $colorGallery;
  }

  &--silver-border {
    &,
    &:last-child {
      border-color: $colorSilverChalice;
    }
  }
}

.accordion-item-header {
  position: relative;
  cursor: pointer;
  padding-left: 40px;
  transition: color $transitionDurationDefault ease;

  &--icon-right {
    padding-left: 0;
    padding-right: 100px;
  }

  &:hover {
    color: $colorActiveState;
  }

  @include mobileLargeMax {
    padding: 0 25px 0 0;
  }
}

.accordion-item-content {
  padding: 25px 0 0 40px;

  &--no-side-padding {
    padding-right: 0;
    padding-left: 0;
  }

  @include mobileLargeMax {
    padding-left: 0;
  }
}

.accordion-item-header-plus {
  position: absolute;
  left: -7px;
  top: -3px;
  height: 100%;
  width: 30px;

  > div {
    position: absolute;
    top: 50%;
    left: 7px;
    width: 17px;
    height: 1px;
    background-color: $colorText;
    transition: transform $transitionDurationDefault ease, background-color $transitionDurationDefault ease;

    &:nth-child(2) {
      transform: rotate(90deg);

      .accordion-item--active & {
        transform: none;
      }
    }

    .accordion-item-header:hover & {
      background-color: $colorActiveState;
    }
  }

  .accordion-item-header--icon-right & {
    top: 50%;
    right: 0;
    left: auto;
    transform: translateY(-50%);
  }

  @include mobileLargeMax {
    left: auto;
    right: 15px;
  }

  @include mobileSmallMax {
    right: 5px;
  }
}
