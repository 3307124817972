.last-seen-title {
  &:not(:last-child) {
    margin-bottom: 25px;
  }
}

.last-seen-items {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
