.layout-section-header {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-bottom: 14px;
  }
}

.layout-section-header-main,
.layout-section-header-aside {
  flex: 0 0 auto;
  max-width: 100%;
}

.layout-section-header-aside {
  margin-left: auto;

  .swiper-pagination {
    white-space: nowrap;
  }
}
