@import '../../../components/header/header-nav';

.header-nav-item {
  color: $colorBrightText;

  &:hover .header-dropdown-label {
    color: $colorBrightText;
  }
}

#header-dropdown-favourite > div.header-dropdown-content > div.mini-cart > button {
  display: none;
}
