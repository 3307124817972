.navigation-root-item {
  @include tabletMin() {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
  }

  @include tabletMax {
    .navigation-items {
      z-index: 5;
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      height: 100%;
      transition: left $transitionDurationDefault ease;
      background-color: $colorGallery;

      > ul {
        display: flex;
      }
    }

    .open > {
      .navigation-items {
        left: 0;
      }
    }
  }

  app-menu-item {
    display: block;

    ul {
      display: none;
      background-color: $colorGallery;
      flex-direction: column;

      @include tabletMin() {
        &[data-depth='1'] {
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          z-index: 5;
        }

        &:not([data-depth='1']) {
          display: none; // Normal Navigation has no subitems.
        }
      }
    }

    &:hover > .navigation-items > ul {
      display: flex;
    }

    @include tabletMin {
      position: relative;

      ul {
        background-color: transparent;
        padding-top: 1px;

        .navigation-item {
          background-color: $colorGallery;
        }
      }

      &[data-depth='0']:first-child ul[data-depth='1'] {
        left: -20px;
        transform: none;
      }

      &[data-depth='0']:last-child ul[data-depth='1'] {
        left: auto;
        right: -20px;
        transform: none;
      }
    }

    @media screen and (min-width: 1500px) {
      &[data-depth='0']:first-child ul[data-depth='1'] {
        left: calc(50% - 10px);
        transform: translateX(-50%);
      }

      &[data-depth='0']:last-child ul[data-depth='1'] {
        left: calc(50% + 10px);
        transform: translateX(-50%);
        right: auto;
      }
    }

    .navigation-item-title {
      display: block;
      white-space: nowrap;
      transition: color $transitionDurationDefault ease, background-color $transitionDurationDefault ease;

      &.active {
        > div {
          color: $colorActiveStateDarkened;

          @include tabletMin {
            font-weight: bold;
          }
        }
      }

      @include tabletMax {
        position: relative;
        display: block;
        transition: color $transitionDurationDefault ease, background-color $transitionDurationDefault ease;
        padding: 12px 40px;

        &:hover {
          background-color: rgba($colorWhite, 0.3);
        }
      }
    }

    &:hover > .navigation-item-title {
      color: $colorActiveState;
    }

    @include tabletMin {
      &:not([data-depth='0']):hover > .navigation-item-title {
        color: $colorBrightText;
        background-color: $colorActiveState;
      }

      &[data-depth='0']:first-child > .navigation-item-title {
        margin-left: -10px;
      }

      &[data-depth='0']:last-child > .navigation-item-title {
        margin-right: -10px;
      }
    }

    .navigation-item-open {
      display: none;
      position: absolute;
      right: 20px;
      top: 50%;
      font-size: 10px;
      padding: 20px;
      transform: translateY(-50%) rotate(-90deg);

      @include tabletMax {
        display: block;
      }

      @include mobileLargeMax {
        right: 0;
      }

      @include mobileSmallMax {
        padding: 10px;
      }
    }

    .navigation-item-back {
      display: none;

      button {
        display: block;
        width: 100%;
        text-align: left;
        padding: 12px 40px;
        font-weight: 700;
        transition: color $transitionDurationDefault ease, background-color $transitionDurationDefault ease;
        background-color: rgba($colorSilverChalice, 0.2);

        &:hover {
          color: $colorActiveState;
          background-color: rgba($colorSilverChalice, 0.1);
        }

        @include mobileLargeMax {
          padding: 12px 20px;
        }

        @include mobileSmallMax {
          padding: 12px 10px;
        }
      }

      @include tabletMax {
        display: block;
      }
    }

    .navigation-item-discover-all {
      display: none;

      > a div {
        font-weight: bold;
      }

      @include tabletMax {
        display: block;
      }
    }
  }

  [data-depth='0'] > .navigation-item-title {
    padding: 12px 10px;
  }

  [data-depth='1'] > .navigation-item-title,
  [data-depth='2'] > .navigation-item-title {
    padding: 12px 30px;
  }

  @include tabletMax {
    [data-depth='0'] > .navigation-item-title,
    [data-depth='1'] > .navigation-item-title,
    [data-depth='2'] > .navigation-item-title {
      padding: 12px 40px;
    }
  }

  @include mobileLargeMax {
    [data-depth='0'] > .navigation-item-title,
    [data-depth='1'] > .navigation-item-title,
    [data-depth='2'] > .navigation-item-title {
      padding: 12px 20px;
    }
  }

  @include mobileSmallMax {
    [data-depth='0'] > .navigation-item-title,
    [data-depth='1'] > .navigation-item-title,
    [data-depth='2'] > .navigation-item-title {
      padding: 12px 10px;
    }
  }
}

.navigation-main-item-title.active {
  color: $colorBlack !important;
}

.navigation-root-item app-menu-item:not([data-depth="0"]):hover > .navigation-item-title {
  background: unset;
  color: black;
}

.navigation-root-item app-menu-item .navigation-item-title.active > div {
  color: $colorActiveState;
}

.navigation-item-title.active {
  box-shadow: inset 0 -1px 0 $colorActiveState;
}

app-menu-item {
  &:hover > .navigation-item-title {
    box-shadow: inset 0 -1px 0 $colorActiveState;
  }
}

.moebelshop {
  background-color: $colorTempUnavailable;

  &.navigation-item-title {
    color: $colorBrightText;
  }

  &:hover {
    color: $colorActiveState;
  }

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 50vw;
    background-color: $colorTempUnavailable;
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 40px 50px 0;
    border-color: transparent $colorTempUnavailable transparent transparent;
    z-index: -1;
  }
  @include tabletMax {
    padding: 12px 10px !important;
  }

  @include mobileLargeMax {
    padding: 12px 10px !important;
  }

  @include mobileSmallMax {
    padding: 12px 10px !important;
  }
}

.specialElementTitle {
  margin-top: 12px;
  @include tabletMax {
    margin-top: 0;
  }

  @include mobileLargeMax {
    margin-top: 0;
  }

  @include mobileSmallMax {
    margin-top: 0;
  }
}

[data-depth='1'] > .navigation-main-item-discover-all {
  display: none !important;
}

[data-depth='1'] > .navigation-item-discover-all {
  display: none !important;
}
