.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagination-pages {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include tabletSmallMax {
    display: none;
  }
}

.pagination-side-change {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $colorScorpion;
  margin-right: 30px;
  transition: color $transitionDurationDefault ease;

  &:hover {
    color: $colorGlacier;
  }

  i {
    font-size: 8px;

    &:last-child {
      margin-left: 5px;
    }

    &:first-child {
      margin-right: 5px;
    }
  }

  &--prev {
    i {
      transform: rotate(180deg);
    }
  }

  &--next {
    margin: 0 0 0 20px;
  }
}

.pagination-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border: 2px solid $colorScorpion;
  color: $colorText;
  transition: color $transitionDurationDefault ease, background-color $transitionDurationDefault ease;

  &:hover {
    background-color: rgba($colorScorpion, 0.7);
    color: $colorBrightText;
  }

  &.active {
    background-color: $colorScorpion;
    color: $colorBrightText;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.pagination-dots {
  margin: 0 20px 0 10px;
}
