.tooltip {
  position: fixed;
  opacity: 0;
  pointer-events: none;
  transition: opacity $transitionDurationDefault ease;
  z-index: $zNavigation - 60;
  background-color: $colorGallery;
  padding: 4px 5px 3px;
  transform: translate(10px, -50%);
  max-width: 250px;
  font-size: 14px;
  color: $colorText;

  a:hover > &,
  div:hover > &,
  button:hover > & {
    opacity: 1;
  }
}
