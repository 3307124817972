@import '../../../includes/cms/videos';

.videos-navigation-item-progress-bar {
  background-color: $colorTempUnavailable;
}

.videos-navigation-item-infos {
  text-transform: none;
  color: $colorWhite;
}

.videos-navigation-item.active,
.videos-navigation-item:hover {
  background: $colorScorpion;
}

.videos-navigation-item {
  border-bottom: 1px solid $colorWhite;
  border-top: 1px solid $colorWhite;
}

.videos-navigation-arrow:hover {
  color: $colorTempUnavailable;
}

.videos {
  background: #f0f0f0;
}

.videos-navigation-arrow {
  color: $colorScorpion;
}
.videos-navigation-arrows {
  margin-top: 10px;
}
