.bg-section {
  margin-top: 64px;
  margin-bottom: 132px;
  padding-top: 100px;
  padding-bottom: 80px;
  background-color: $colorGallery;

  &--slim {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  &--blue-light {
    background-color: scale-color($colorCeruleanBlue, $lightness: 85%);
  }

  &--remove-margin-top {
    margin-top: 0;
  }

  &--remove-margin-bottom {
    margin-bottom: 0;
  }

  &--reset-footer-margin {
    margin-bottom: -80px;
  }

  &--image {
    background-size: cover;
    background-position: 50% 50%;
  }

  & + & {
    margin-top: -64px;
  }
}
