.delivery-status {
  display: inline-flex;
  align-items: center;
}

.delivery-status-dot {
  width: 19px;
  height: 19px;
  background-color: $colorUnavailable;
  border-radius: 50%;

  &:not(:last-child) {
    margin-right: 6px;
  }

  .available & {
    background-color: $colorAvailable;
  }

  .temp-unavailable & {
    background-color: $colorTempUnavailable;
  }
}
