// No Import needed, as original Fonts will not be used.
// @import '/assets/scss/base/fonts';

/* =================================================
        Fonts.
================================================= */

@font-face {
  font-family: $fontFutura;
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  src: url($fontsBasePath + '../theme/fonts/futurabook.ttf') format('TrueType');
}

@font-face {
  font-family: $fontFutura;
  font-weight: 600;
  font-display: swap;
  font-style: normal;
  src: url($fontsBasePath + '../theme/fonts/futurabold.ttf') format('TrueType');
}
