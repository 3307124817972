.cookie-bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1020;
  padding-top: 34px;
  padding-bottom: 34px;
  text-align: center;
  color: $colorWhite;
  background-color: $colorBlack;
}

.cookie-bar-inner {
  @include tabletMax {
    display: block;
  }
}

.cookie-bar-content {
  padding-right: 30px;

  @include tabletMax {
    padding-right: 0;
    margin-bottom: 20px;
  }
}

.cookie-bar-buttons {
  @include tabletMax {
    white-space: normal;

    button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
