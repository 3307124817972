.layout-edit-block {
  display: flex;

  &--valign-center {
    align-items: center;
  }
}

.layout-edit-block-edit-main,
.layout-edit-block-edit-footer {
  flex: 0 0 auto;
}

.layout-edit-block-main {
  width: 220px;

  & + * {
    margin-left: auto;
  }

  &--auto-width {
    width: auto;
  }
}

.layout-edit-block-edit-footer {
  margin-left: 20px;
  align-self: center;

  i + i {
    margin-left: -0.6em;
  }

  i {
    transition: color $transitionDurationDefault ease;
    cursor: pointer;

    &:hover {
      color: $colorActiveState;
    }
  }

  &--valign-top {
    align-self: flex-start;
  }

  .layout-edit-block--valign-center & {
    margin-top: 0;
  }
}

.layout-edit-block-selection label:empty {
  height: 1em;
  margin-bottom: 0 !important;
}
