/* =================================================
        Keyframes.
================================================= */

@keyframes quantitySelectSizeUp {
  0% {
    width: 0;
    opacity: 0;
  }

  50% {
    width: 80px;
    opacity: 0;
  }

  100% {
    width: 80px;
    opacity: 1;
  }
}

@keyframes quantitySelectSizeDown {
  0% {
    width: 80px;
    opacity: 1;
  }

  50% {
    width: 80px;
    opacity: 0;
  }

  100% {
    width: 0;
    opacity: 0;
  }
}
