.footer-main {
  padding-top: 65px;
  padding-bottom: 33px;
  background-color: $colorScorpion;

  @include mobileLargeMax {
    padding-left: 0;
    padding-right: 0;
  }
}

.footer-main-logo {
  &:not(:last-child) {
    margin-bottom: 63px;
  }

  img {
    width: 190px;
    margin: 0 auto;
  }

  @include mobileLargeMax {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mobileSmallMax {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.footer-main-socials {
  @include mobileLargeMax {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mobileSmallMax {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.footer-main-socials-title {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.footer-main-socials-items {
  margin-bottom: -20px;
}

.footer-main-socials-item {
  width: 32px;
  margin: 0 20px 20px;
  transition: opacity $transitionDurationDefault ease;

  &:hover {
    opacity: 0.6;
  }
}
