/* **************************************************************************
 *  MEDIA MOTION AG                                                         *
 ****************************************************************************

    Funktion      edit-hover bem module

*****************************************************************************/

/*
.edit-hover

Styleguide classes.edit-hover
*/

.edit-hover {
  display: inline-block;
  position: relative;
  z-index: 1;
  color: inherit;
  background-color: transparent;
  transition: color $transitionDurationDefault, background $transitionDurationDefault;

  &--right-distance {
    margin-right: 8px;
  }

  &--left-distance {
    margin-left: 8px;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%) scale(0);
    width: 30px;
    height: 30px;
    transition: background $transitionDurationDefault, transform $transitionDurationDefault;
  }

  .edit-hover-container:hover &,
  &:hover {
    color: $colorWhite;

    &::after {
      background-color: $colorCeruleanBlue;
      transform: translate(-50%, -50%) scale(1);
    }
  }
}
