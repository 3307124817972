.post-tile {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 0 0 auto;
  max-width: 100%;
  min-height: 786px;
  margin-top: 30px;
  padding: 60px 180px;
  color: $colorWhite;
  background-size: cover;
  background-position: 50% 50%;
  transition: margin $transitionDurationDefault;

  .swiper-slide-active & {
    margin-top: 0;
  }

  @include desktopMax {
    padding: 40px 90px;
  }

  @include mobileLargeMax {
    padding: 20px;
    min-height: 400px;
  }
}

.post-tile-title {
  margin-bottom: 30px;
}

.post-tile-teaser {
  margin-bottom: 80px;
  padding-right: 40%;
}

.post-tile-meta {
  text-transform: uppercase;
}
