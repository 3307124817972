.socials {
  position: fixed;
  right: 0;
  top: 35%;
  z-index: $zNavigation - 40;
}

.socials-item {
  position: relative;
  display: block;
  background-color: $colorMineShaft;
  color: $colorBrightText;
  height: 40px;
  width: 40px;
  transition: background-color $transitionDurationDefault ease;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 20px;
    transform: translate(-50%, -50%);
  }

  &--share {
    i {
      left: 0;
      top: 0;
      transform: none;
      font-size: 40px;
    }
  }

  &:hover {
    background-color: $colorScorpion;
  }
}
