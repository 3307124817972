.mini-cart-item {
  padding: 20px 10px;

  &--hint {
    padding: 10px 20px;
    color: $colorBrightText;
    background-color: $colorScorpion;
  }

  &--footer {
    border-top-color: $colorSilverChalice;
    text-align: right;
    color: $colorSilverChalice;

    [class*='icon-'] {
      display: inline-block;
      margin-top: -0.2em;
      margin-right: 10px;
      vertical-align: middle;
      font-size: 18px;
    }
  }

  & + & {
    border-top: 1px solid $colorText;
  }

  .btn {
    background-color: $colorScorpion;

    &:hover {
      background-color: $colorText;
    }

    [class*='icon-'] {
      display: inline-block;
      margin-top: -0.2em;
      margin-right: 10px;
      font-size: 26px;
      vertical-align: middle;
    }
  }

  &.regular {
    border-top: none !important;
  }
}

.mini-cart-item-justify {
  display: flex;
  justify-content: space-between;
  align-items: center;

  [class*='icon-'] {
    font-size: 26px;
  }
}

.mini-cart-item-total {
  display: flex;
  align-items: flex-end;

  strong {
    font-weight: 700;
  }
}

.mini-cart-item-total-description {
  flex: 1 1 auto;
}

.mini-cart-item-total-price {
  flex: 0 0 auto;
  margin-left: 10px;
  font-size: 1.2em; // Mixing em with px is not a good idea.
  white-space: nowrap;
}

.mini-cart-item.linked:hover {
  background: $colorGallery;
}

app-cart-menu .mini-cart-item:nth-child(2) {
  border-top: none !important;
}
