.footer-info-items-small {
  width: calc(100% + 30px);
  margin-left: -30px;
  margin-bottom: -40px;

  @include tabletSmallMax {
    margin-bottom: -20px;
  }
}

.footer-info-item-small {
  width: calc(16.66% - 30px);
  height: 80px;
  margin-left: 30px;
  margin-bottom: 40px;

  img {
    max-height: 100%;
    max-width: 100%;
    flex: 0 0 auto;
  }

  @include desktopSmallMax {
    width: calc(25% - 30px);
  }

  @include tabletMax {
    width: calc(16.66% - 30px);
  }

  @include tabletSmallMax {
    width: calc(25% - 30px);
    margin-bottom: 20px;
  }

  @include mobileSmallMax {
    width: calc(50% - 30px);
  }
}

a.footer-info-item-small {
  transition: opacity $transitionDurationDefault ease;

  &:hover {
    opacity: 0.6;
  }
}
