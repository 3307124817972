/* =================================================
        Range Slider.
================================================= */

.range-slider {
  width: 100%;
}

.range-slider-element {
  position: relative;
  width: 100%;
  height: 15px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    height: 2px;
    width: 100%;
    background-color: $colorSilverChalice;
    transform: translateY(-50%);
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.range-slider-knob {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid $colorSilverChalice;
  background-color: $colorWhite;
  box-shadow: 0 2px 7px 0 rgba($colorBlack, 0.35);

  &:last-child {
    left: auto;
    right: 0;
  }
}

.range-slider-input {
  display: flex;
  align-items: center;
  width: 120px;

  &::before {
    content: 'EUR ';
    margin-right: 5px;
    font-size: 14px;
    line-height: 1.357;
  }

  input {
    width: 100%;
    flex-shrink: 1;
  }
}
