.listing-active-filters {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;

  .btn-slim {
    margin-bottom: 12px;
  }

  &:not(:last-child) {
    margin: 30px 0;
  }
}

.listing-active-filter {
  display: inline-block;
  position: relative;
  background-color: $colorCeruleanBlue;
  padding: 2px 26px 1px 6px;
  color: $colorBrightText;
  margin-bottom: 12px;

  &:not(:last-child) {
    margin-right: 12px;
  }

  span {
    color: rgba($colorBrightText, 0.7);
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 20px;
    border-left: 1px solid $colorWhite;
    text-align: center;
    color: $colorWhite;
    background-color: transparent;
    transition: background-color $transitionDurationDefault ease;

    i {
      font-family: $fontIcons;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      font-size: 9px;
      line-height: 0;
      transform: translateY(-50%);
      transition: transform $transitionDurationDefault ease;
    }

    &:hover {
      background-color: rgba($colorWhite, 0.1);

      i {
        transform: translateY(-50%) scale(1.2);
      }
    }
  }

  @include tabletSmallMax {
    padding: 2px 50px 1px 6px;

    span {
      display: block;
    }
  }
}
