.header-search-result-products-item {
  transition: color $transitionDurationDefault ease;
  padding: 3px 0;

  &:not(:last-child) {
    border-bottom: 1px solid $colorSilverChalice;
  }

  .no-touchevents &:hover {
    color: $colorActiveState;
  }
}

.header-search-result-products-item-image {
  //width: 90px;
  flex-grow: 0;
  flex-shrink: 0;

  &:not(:last-child) {
    margin-right: 15px;
  }

  img {
    width: 90px;
    padding: 15px;
  }
}

.header-search-result-products-item-info,
.header-search-result-products-item-text {
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 16px;
}

.header-search-result-products-item-text .bold {
  margin-bottom: 5px;
}

.header-search-result-products-item-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  margin-right: 15px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 45px;
  width: 45px;
  color: $colorText;
  transition: color $transitionDurationDefault ease, background-color $transitionDurationDefault ease;

  .touchevents &,
  .no-touchevents .header-search-result-products-item:hover & {
    color: $colorBrightText;
    background-color: $colorActiveState;
  }

  &:hover {
    background-color: $colorAvailable;
    color: $colorWhite;
  }
}

.header-search-result-products-item:hover {
  border-right: 3px solid $colorAvailable;
  .bold {
    text-decoration: underline;
  }
}

.header-search-result-category-item:hover {
  text-decoration: underline;
}

.card-product-pseudo-price {
  display: none !important;
}
