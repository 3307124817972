/* =================================================================
        Overrides for Grid Items from CSS Wizardry Grid.
================================================================= */

/* stylelint-disable */
.grid {
  font-size: 0;

  @include tabletMax {
    margin-left: -18px;
  }

  @include mobileLargeMax {
    margin-left: -10px;
  }
}

.grid__item {
  @include tabletMax {
    padding-left: 18px;
  }

  @include mobileLargeMax {
    padding-left: 10px;
  }
}
/* stylelint-enable */
