.header-info-container {
  background-color: $colorScorpion;

  @include tabletMax {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
  }
}

.header-info-mobile-opener {
  padding: 5px 0;

  i {
    padding-top: 2px;
    transition: transform $transitionDurationDefault ease;

    .header-info-container.open & {
      transform: rotate(-180deg);
    }
  }

  @include tabletMin {
    display: none;
  }
}

.header-info-container.open {
  .header-info {
    @include tabletMax {
      display: block;
      transition: transform $transitionDurationDefault ease;
    }
  }
}

.header-info {
  width: 100%;
  justify-content: space-between;
  padding: 9px 0;

  @include tabletMin {
    display: flex !important; // Overwrite JS values.
    height: auto !important;
  }

  @include tabletMax {
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }
}

.header-info-col {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;

  @include tabletMax {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

.header-info-item,
.header-info-item button {
  color: $colorBrightText;
}

.header-info-item {
  i {
    font-size: 20px;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  a {
    transition: opacity $transitionDurationDefault ease;

    &:hover {
      opacity: 0.7;
    }
  }

  &:not(:last-child) {
    margin-right: 10px;
  }

  @include tabletMax {
    &:not(.tablet-hidden) {
      display: block;
    }

    i {
      display: none;
    }

    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 12px;
    }
  }
}
