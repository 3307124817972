@import '../../../includes/cms/newsletter';

.newsletter {
  .bg-section {
    background: linear-gradient(180deg, rgba(0, 102, 80, 1) 0%, rgba(25, 61, 52, 1) 100%) !important;
  }

  .btn {
    background-color: $colorWhite;
    border-color: $colorWhite;
    color: $colorText;

    &:hover {
      background-color: $colorTempUnavailable;
      border-color: $colorTempUnavailable;
      color: $colorWhite;
    }
  }
}
