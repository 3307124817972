.mobile-footer-nav-container {
  position: fixed;
  bottom: 0;
  z-index: 99;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background-color: #393939;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}


.mobile-nav-button-container {
  flex: 1;
  height: 100%;
   &:not(:last-child) {
     border-right: 1px solid white;
   }
}

.mobile-nav-button {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    color: white;
    font-size: 24px;
  }
}

.mobile-nav-content-container {
  bottom: 70px;
  background-color: #585858;
  width: 100%;
  max-height: 56vh;
  position: fixed;
  z-index: 9999;
  overflow-y: auto;
}

.mobile-nav-content {
  width: 100%;
}

.mobile-nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  color: white;
  position: sticky;
  top: 0;
  background-color: #1a1a1a;
  z-index: 9999
}


