.listing-filters {
  width: 300px;
  margin-right: 20px;

  @include tabletMax {
    position: fixed;
    display: flex;
    flex-direction: column;
    left: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $colorWhite;
    transition: left $transitionDurationDefault ease;
    z-index: $zNavigation + 10;

    &.open {
      left: 0;
    }
  }
}

.listing-filters-inner {
  display: flex;
  flex-direction: column;

  @include tabletMax {
    overflow: auto;
    flex-grow: 0;
    flex-shrink: 1;
    height: 100%;
    padding: 25px 10px 60px;
  }
}

.listing-filters-show {
  display: none;
  position: absolute;
  bottom: 10px;
  left: 30px;
  width: calc(100% - 60px);
  color: $colorBrightText;
  background-color: $colorActiveState;
  padding: 9px 10px;
  z-index: 10;

  @include tabletMax {
    display: block;
  }
}

.listing-filters-categories {
  &:not(:last-child) {
    margin-bottom: 53px;
  }

  @include tabletMax {
    display: none;
  }
}

.listing-filters-categories-title {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.listing-filters-categories-item {
  display: block;
  font-size: 18px;
  line-height: 1.3;
  padding: 7px 0;
  transition: background-color $transitionDurationDefault ease, padding-left $transitionDurationDefault ease;

  &:hover {
    padding-left: 9px;
    background-color: $colorGallery;
  }

  i {
    display: inline-block;
    font-family: $fontIcons;
    margin-left: 5px;
    font-size: 8px;
    vertical-align: middle;
    padding-bottom: 2px;
  }
}

.header-detail {
  .listing-filters {
    width: 100%;
  }
  .listing-filters-item {
    &:hover {
    }
  }
  .listing-filters-item-reset,
  .listing-filters-item-search {
    display: none;
  }
}
