/* =================================================
        Buttons.
================================================= */

button {
  outline: none;
  border: none;
  padding: 0;
  background-color: transparent;
  font-family: $fontDefault;
  margin: 0;

  &:disabled {
    cursor: default;
  }
}

.btn-slim {
  @extend .default-font-sizes;
  @extend .default-font-sizes--small;
  @extend .underlined;
  color: $colorText;
  transition: color $transitionDurationDefault ease;

  &::after {
    transition: background-color $transitionDurationDefault ease, width $transitionDurationDefault ease;
  }

  &:hover {
    color: $colorActiveState;

    &::after {
      background-color: $colorActiveState;
    }
  }
}

.btn {
  display: inline-block;
  @extend .default-font-sizes--medium;
  background-color: $colorMineShaft;
  color: $colorWhite;
  padding: 12px 38px;
  border: 2px solid $colorMineShaft;
  transition: color $transitionDurationDefault ease, background-color $transitionDurationDefault ease,
    border-color $transitionDurationDefault ease;
  cursor: pointer;

  &--wide {
    width: 100%;
  }

  &--small {
    padding-top: 7px;
    padding-bottom: 5px;
  }

  &--additions {
    @extend .default-font-sizes--small;
    display: inline-flex;
    align-items: center;
    padding: 6px 8px 4px;
    border-color: $colorGallery;
    line-height: 1.1;
    color: $colorBlack;
    background-color: $colorGallery;

    @include mobileLargeMax {
      padding: 3px 4px 2px;
    }
  }

  &--additions--large {
    padding: 10px 10px 8px;
  }

  &--additions &-icon {
    margin-left: 10px;
  }

  &--additions &-icon-left,
  &--edit [class*='icon-'] {
    margin-right: 10px;
  }

  &--additions-centered {
    @extend .btn--additions;
    display: block;
    text-align: center;
  }

  &:hover,
  &--active,
  &.active {
    color: $colorBrightText;
    background-color: $colorActiveState;
    border-color: $colorActiveState;
  }

  &--active:hover,
  &.active:hover {
    color: $colorBrightText;
    background-color: $colorActiveStateDarkened;
    border-color: $colorActiveStateDarkened;
  }

  &--bright {
    background-color: $colorGallery;
    border-color: $colorGallery;
    color: $colorScorpion;

    &:hover {
      color: $colorBrightText;
      background-color: $colorActiveState;
      border-color: $colorActiveState;
    }
  }

  &--white {
    color: $colorBlack;
    background-color: $colorWhite;
    border-color: $colorWhite;

    &:hover {
      color: $colorBrightText;
      background-color: $colorActiveState;
      border-color: $colorActiveState;
    }
  }

  &--outline {
    border-color: $colorBlack;
    border-width: 1px;
    color: $colorBlack;
    background-color: transparent;

    &:hover {
      color: $colorBrightText;
      background-color: $colorActiveState;
      border-color: $colorActiveState;
    }
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
    width: 33px;
    height: 33px;
  }

  &--edit {
    @extend .btn--additions;
    @extend .btn--outline;
    @extend .btn--white;
    color: $colorSilverChalice;
    border-color: $colorSilverChalice;

    .icon-edit {
      font-size: 1.2em;
    }
  }

  & + * {
    vertical-align: middle;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
}

.btn-icon {
  display: inline-block;
  vertical-align: middle;
}
