.check-products {
  @include tabletMax {
    tr.layout-cart-table-row {
      border-bottom: 1px solid $colorText !important;
    }

    .layout-cart-table-cell--bottom {
      border-bottom-width: 0 !important;
    }

    .layout-cart-table-tr-footer {
      margin-top: 0;
    }
  }
}
