/* ===========================================================
        Fonts.
=========================================================== */
$fontNunito: 'Nunito Sans';
$fontRoboto: 'Roboto';
$fontDefault: $fontNunito, 'Calibri', 'Arial', sans-serif;
$fontDefaultHeadline: $fontRoboto, 'Calibri', 'Arial', sans-serif;
$fontIcons: 'icomoon';

/* ===========================================================
        Element Sizes.
=========================================================== */
$containerMaxWidth: 1260px;
$containerMediumMaxWidth: 1240px;
$containerSmallMaxWidth: 1000px;
$textMaxWidth: 770px;
$textMaxWidthSmall: 400px;

/* ===========================================================
        Global Breakpoints.
=========================================================== */
$desktopBreakpoint: 1440px;
$desktopSmallBreakpoint: 1330px;
$desktopVerySmallBreakpoint: 1240px;
$tabletBreakpoint: 1024px;
$tabletSmallBreakpoint: 769px;
$mobileLargeBreakpoint: 600px;
$mobileSmallBreakpoint: 480px;
$mobileSuperSmallBreakpoint: 360px;

/* ========================================================================
        Colors. Names from https://chir.ag/projects/name-that-color/
======================================================================== */
// ToDo: Generalize all Colors so they can be quickly switched.
$colorBlack: #000000;
$colorCodGray: #1b1b1b;
$colorMineShaft: #393939;
$colorScorpion: #585858;
$colorSilverChalice: #afafaf;
$colorGallery: #f0f0f0;
$colorAlabaster: #fafafa;
$colorWhite: #ffffff;
$colorCeruleanBlue: #2a6fb2;
$colorCeruleanBlueDarkened: #1d4878;
$colorGlacier: #6db6c0;
$colorMonza: #d0021b;
$colorJaffa: #f48a42;

// Generalized Colors.
$colorText: $colorBlack;
$colorBrightText: $colorWhite;
$colorAvailable: $colorGlacier;
$colorActiveState: $colorCeruleanBlue;
$colorActiveStateDarkened: $colorCeruleanBlueDarkened;
$colorUnavailable: $colorMonza;
$colorTempUnavailable: $colorJaffa;

/* ===========================================================
        Asset Paths.
=========================================================== */
$assetBasePath: '/assets/';
$fontsBasePath: $assetBasePath + 'fonts/';

/* ===========================================================
        Z-index.
=========================================================== */
$zNavigation: 1000;
$zFooter: 900;
$zCookie: 950;

/* ===========================================================
        Transition Durations.
=========================================================== */
$transitionDurationDefault: 350ms;

/* ===========================================================
        Spacings.
=========================================================== */
$default-padding: 40px !default;
$default-padding--mobile-large-max: 20px !default;
$default-padding--mobile-small-max: 10px !default;

$gutter: 24px;
