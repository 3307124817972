.order-progress-inner {
  display: flex;
  margin-top: 20px;
  margin-bottom: 36px;

  @include tabletMax {
    justify-content: space-between;
  }

  @include tabletSmallMax {
    justify-content: flex-end;
  }
}

.order-progress-item {
  display: flex;
  align-items: center;
  flex: 0 1 0;
  opacity: 0.4;
  transition: opacity $transitionDurationDefault;

  @include tabletMax {
    flex-basis: auto;
  }

  > * {
    flex: 0 0 auto;
  }

  & + & {
    flex-grow: 1;

    @include tabletMax {
      flex-grow: 0;
    }

    @include tabletSmallMax {
      margin-left: 10px;
    }
  }

  & + &::before {
    content: '';
    display: block;
    flex: 1 1 auto;
    width: auto;
    height: 1px;
    margin-left: 18px;
    background-color: $colorScorpion;
    transition: margin-left $transitionDurationDefault;

    @include tabletMax {
      display: none;
    }
  }

  &--title {
    display: none;
    flex: 0 0 auto;
    align-self: center;
    margin-right: auto;
    font-size: 18px;
    font-weight: 700;

    @include tabletSmallMax {
      display: block;
    }
  }

  &:hover,
  &.active,
  &.done {
    opacity: 1;
  }

  & + &:hover::before {
    margin-left: 30px;
  }
}

.order-progress-item-count {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $colorScorpion;
  width: 24px;
  height: 24px;
  border-radius: 50%;

  .order-progress-item.active & {
    color: $colorBrightText;
    background-color: $colorScorpion;
  }
}

.order-progress-item-description {
  margin-left: 10px;

  @include tabletSmallMax {
    display: block;
    width: 0;
    margin-left: 0;
    text-indent: -9999px;
  }
}
