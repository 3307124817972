@import '../../base/buttons';

.btn {
  &:hover,
  &--active,
  &.active {
    color: $colorBrightText;
    background-color: $colorTempUnavailable;
    border-color: $colorTempUnavailable;
  }

  &--bright {
    background-color: $colorGallery;
    border-color: $colorGallery;
    color: $colorScorpion;

    &:hover {
      color: $colorBrightText;
      background-color: $colorTempUnavailable;
      border-color: $colorTempUnavailable;
    }
  }
}
