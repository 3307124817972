@use 'sass:math';

$popup--gutter: 44px !default;

.popup {
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  background-color: $colorScorpion;
  visibility: hidden;
  opacity: 0;
  transition: visibility $transitionDurationDefault ease, opacity $transitionDurationDefault ease;

  &--active {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
}

.popup-inner {
  overflow: hidden;
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 120px;
  padding-bottom: 120px;
  max-width: 100%;
}

.popup-close {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 17px;
  margin-right: 15px;
  font-size: 18px;
}

.popup-content-wrapper {
  margin: math.div($popup--gutter, -2);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.popup-content {
  display: flex;
  max-width: 100%;
  padding: $popup--gutter * 0.5;
}
