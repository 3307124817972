.listing-items {
  align-items: stretch;

  /* stylelint-disable */
  .grid__item {
    display: flex;
  }
  /* stylelint-enable */

  &:not(:last-child) {
    margin-bottom: 10px;

    &.list {
      margin-bottom: 85px;
    }

    @include tabletMax {
      margin-bottom: 20px;

      &.list {
        margin-bottom: 25px;
      }
    }

    @include tabletSmallMax {
      margin-bottom: 32px;

      &.list {
        margin-bottom: 30px;
      }
    }
  }
}

.listing-item {
  margin-bottom: 40px;

  .list & {
    margin-bottom: 15px;
  }

  @include tabletMax {
    margin-bottom: 30px;

    .list & {
      margin-bottom: 15px;
    }
  }

  @include tabletSmallMax {
    margin-bottom: 18px;

    .list & {
      margin-bottom: 10px;
    }
  }
}
