.footer-main-nav {
  width: calc(100% + 21px);
  margin-left: -21px;
  margin-bottom: -40px;

  &:not(:last-child) {
    margin-bottom: 35px;
  }

  @include mobileLargeMax {
    margin-left: 0;
    width: 100%;
  }
}
