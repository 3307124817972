@import '../../../components/header/navigation-item';

.navigation-item {
  &--special[data-depth='0'] {
    > .navigation-item-title {
      color: $colorBrightText;

      &:hover {
        color: $colorActiveState;
      }
    }

    &::before {
      content: '';
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      width: 50vw;
      background-color: $colorTempUnavailable;
      z-index: -1;

      @include desktopVerySmallMax {
        left: 15px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      right: 100%;
      top: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 40px 50px 0;
      border-color: transparent $colorTempUnavailable transparent transparent;
      z-index: -1;

      @include desktopVerySmallMax {
        border-width: 0 35px 50px 0;
        right: calc(100% - 15px);
      }
    }

    @include tabletMax {
      &::before {
        display: none;
      }

      &:after {
        display: none;
      }
    }
  }
}
