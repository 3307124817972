.tab-article-description-text {
  display: inline-block;
  width: calc(100% - 370px);

  @include tabletMax() {
    width: calc(100% - 250px);
  }

  @include mobileLargeMax() {
    width: 100%;
  }
}
