/* =================================================
        Header.
================================================= */

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $colorWhite;
  z-index: $zNavigation;
}

.header-top {
  @include tabletMax {
    padding-top: 27px;
  }
}

// Add Padding of Header height to content.
.content-inner {
  padding-top: 288px;

  @include tabletMax {
    padding-top: 172px;
  }

  @include tabletSmallMax {
    padding-top: 141px;
  }

  @include mobileLargeMax {
    padding-top: 125px;
  }
}
