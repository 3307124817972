@use 'sass:math';

.layout-sibling-nav {
  display: flex;
  align-items: center;
  margin-right: math.div($gutter, -2);
  margin-left: math.div($gutter, -2);

  @include tabletMax {
    display: block;
  }
}

.layout-sibling-nav-item {
  flex: 0 0 33%;
  padding: $gutter * 0.5;

  & + &,
  &--next {
    margin-left: auto;
  }
}
