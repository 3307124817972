/* =================================================
        General Form styling.
================================================= */

@use "sass:math";

input {
  font-family: $fontDefault;
}

textarea {
  resize: vertical;
  font-family: $fontDefault;
}

.form {
  legend,
  .legend {
    display: block;
    margin-bottom: 10px;
  }

  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    left: -9000px;
    opacity: 0;

    &:disabled,
    &:disabled + label {
      opacity: 0.4;
      pointer-events: none;
    }

    & + label {
      position: relative;
      display: block;
      cursor: pointer;
      padding-left: 29px;
      width: 100%;
      margin-bottom: 8px;
      color: $colorText;
      transition: color $transitionDurationDefault ease;

      &::before,
      &::after {
        margin-top: 0.1em;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
        border: 1px solid $colorText;
        transition: border-color $transitionDurationDefault ease;
      }

      &::after {
        content: '\e90d';
        font-family: $fontIcons;
        position: absolute;
        top: 8px;
        left: 8px;
        font-size: 0;
        color: $colorActiveState;
        opacity: 0;
        transform: translate(-50%, -50%);
        transition: font-size $transitionDurationDefault ease, opacity $transitionDurationDefault ease;
      }

      .right {
        position: absolute;
        top: 0;
        right: 0;
      }

      &:hover {
        color: $colorActiveState;

        &::before {
          border-color: $colorActiveState;
        }
      }
    }

    &:checked + label {
      color: $colorActiveState;

      &::before {
        border-color: $colorActiveState;
      }

      &::after {
        font-size: 16px;
        opacity: 1;
      }
    }

    &:disabled + label {
      color: $colorSilverChalice;

      &::before {
        border-color: $colorSilverChalice;
      }

      &::after {
        content: '';
        top: -2px;
        width: 1px;
        height: #{math.div(14px, cos(45deg))};
        background-color: $colorSilverChalice;
        opacity: 1;
        transform: rotate(45deg);
      }
    }
  }

  input[type='radio'] {
    & + label {
      &::before {
        height: 16px;
        width: 16px;
        border-radius: 50%;
      }

      &::after {
        content: '';
        width: 0;
        height: 0;
        background-color: $colorActiveState;
        opacity: 0;
        border-radius: 50%;
        transition: width $transitionDurationDefault ease, height $transitionDurationDefault ease,
          opacity $transitionDurationDefault ease;
      }
    }

    &:checked + label {
      &::after {
        width: 8px;
        height: 8px;
      }
    }

    &:disabled + label::after {
      top: 0;
    }
  }

  input[type='search'] {
    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }

    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }
}

.input-hidden {
  position: absolute;
  left: -9999px;

  ~ .btn,
  ~ [type='submit'],
  ~ button {
    display: none;
  }

  &:focus,
  &--active {
    left: 0;
    position: relative;

    ~ .btn,
    ~ [type='submit'],
    ~ button {
      margin-top: 20px;
      display: block;
    }
  }
}

.label-text {
  @extend .default-font-sizes;
  @extend .default-font-sizes--small;
  display: block;
  margin-bottom: 10px;
}

.input-text {
  width: 100%;
  background-color: #f0f0f0;
  border: none;
  padding: 9px 27px 9px 13px;

  &--border {
    border: 1px solid $colorSilverChalice;
  }

  &--small-cart {
    padding-right: 10px;
    padding-left: 10px;
  }

  &--text-area-small {
    height: 50px;
  }

  &--additions {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  &--wide {
    width: 100%;
  }

  &--large {
    font-size: 18px;
    padding-top: 14px;
    padding-bottom: 14px;
    margin-bottom: $gutter;
  }

  &--white {
    color: $colorBlack;
    background-color: $colorWhite;
  }

  &--no-bottom-margin {
    margin-bottom: 0;
  }
}

.input-text-wrapper {
  position: relative;
}

.input-text-wrapper-icon {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 8px;
  transform: translateY(-50%);
  font-size: 1.4em;
  color: $colorSilverChalice;
  transition: font-size $transitionDurationDefault;

  input:focus ~ & {
    font-size: 0.8em;
  }
}

input[type='search'].input-text {
  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.container-length {
  input {
    margin-top: 20px;
    border-bottom: 1px solid black;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
}
