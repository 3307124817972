/* =================================================
        Global Mixins.
================================================= */

@mixin desktopMin {
  @media screen and (min-width: $desktopBreakpoint + 1px) {
    @content;
  }
}

@mixin desktopMax {
  @media screen and (max-width: $desktopBreakpoint) {
    @content;
  }
}

@mixin desktopSmallMin {
  @media screen and (min-width: $desktopSmallBreakpoint + 1px) {
    @content;
  }
}

@mixin desktopSmallMax {
  @media screen and (max-width: $desktopSmallBreakpoint) {
    @content;
  }
}

@mixin desktopVerySmallMin {
  @media screen and (min-width: $desktopVerySmallBreakpoint + 1px) {
    @content;
  }
}

@mixin desktopVerySmallMax {
  @media screen and (max-width: $desktopVerySmallBreakpoint) {
    @content;
  }
}

@mixin tabletMin {
  @media screen and (min-width: $tabletBreakpoint + 1px) {
    @content;
  }
}

@mixin tabletMax {
  @media screen and (max-width: $tabletBreakpoint) {
    @content;
  }
}

@mixin tabletSmallMin {
  @media screen and (min-width: $tabletSmallBreakpoint + 1px) {
    @content;
  }
}

@mixin tabletSmallMax {
  @media screen and (max-width: $tabletSmallBreakpoint) {
    @content;
  }
}

@mixin mobileLargeMin {
  @media screen and (min-width: $mobileLargeBreakpoint + 1px) {
    @content;
  }
}

@mixin mobileLargeMax {
  @media screen and (max-width: $mobileLargeBreakpoint) {
    @content;
  }
}

@mixin mobileSmallMin {
  @media screen and (min-width: $mobileSmallBreakpoint + 1px) {
    @content;
  }
}

@mixin mobileSmallMax {
  @media screen and (max-width: $mobileSmallBreakpoint) {
    @content;
  }
}

@mixin mobileSuperSmallMin {
  @media screen and (min-width: $mobileSuperSmallBreakpoint + 1px) {
    @content;
  }
}

@mixin mobileSuperSmallMax {
  @media screen and (max-width: $mobileSuperSmallBreakpoint) {
    @content;
  }
}

/* IE10+ only CSS styles go here */
@mixin ieOnly {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin stretched($position: absolute) {
  position: $position;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin centerAbsolute {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

@mixin breakWord {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@mixin clearStyle {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: none;
  box-shadow: none;
  appearance: none;
  outline: none;
}
