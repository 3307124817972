@use 'sass:math';

$p-grid--gutter: 22px !default;
$p-grid--gutter--small-gutter: 10px !default;
$p-grid--gutter--large-gutter: 50px !default;

.p-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 math.div($p-grid--gutter, -2);

  &--gutter-margin-top {
    margin-top: $p-grid--gutter * 0.5;
  }

  &--valign-center {
    align-items: center;
  }

  &--halign-center {
    justify-content: space-around;
  }

  &--small-gutter {
    margin: 0 math.div($p-grid--gutter--small-gutter, -2);
  }

  &--large-gutter {
    margin: 0 math.div($p-grid--gutter--large-gutter, -2);
  }
}

.p-grid-item {
  flex: 0 0 auto;
  padding: $p-grid--gutter * 0.5;

  @for $i from 1 through 12 {
    &--w-#{$i}-12 {
      width: math.div(100%, 12) * $i;
    }
  }

  &--small-gutter {
    padding: ($p-grid--gutter--small-gutter * 0.5);
  }

  &--large-gutter {
    padding: ($p-grid--gutter--large-gutter * 0.5);
  }

  &--w-1-5 {
    width: (100% * 0.2);
  }

  .p-grid--equal-height & {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    > * {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }

  @include tabletMax {
    @for $i from 1 through 12 {
      &--w-#{$i}-12-tablet {
        width: math.div(100%, 12) * $i;
      }
    }

    &.tablet-hidden {
      display: none;
    }
  }

  @include mobileLargeMax {
    @for $i from 1 through 12 {
      &--w-#{$i}-12-mobile {
        width: math.div(100%, 12) * $i;
      }
    }

    &.mobile-hidden {
      display: none;
    }
  }
}
