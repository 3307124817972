// iOS button color bug
@supports (-webkit-touch-callout: none) {
  html {
    * button {
      color: #000000;
    }
  }
}

.mat-header-cell {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

// TODO !important not best practice
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell,
td.layout-cart-table-cell {
  padding: 20px 10px 20px 11px !important;
  border-bottom: 0px !important;

  &--slim {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  &--mobile-border {
    @include tabletMax {
      border-top: 1px solid $colorSilverChalice !important;
      border-bottom: 1px solid $colorSilverChalice !important;
    }
  }
}

tr.mat-row {
  @include tabletMax {
    height: auto !important;
  }
}

.search-chip-list {
  mat-chip,
  .mat-chip {
    font-size: 10px;
    min-height: 24px;
  }
}

.mat-form-field {
  width: 80%;
}

app-login-dialog {
  .mat-form-field {
    width: 100% !important;
  }
}

select.mat-input-element {
  font-size: 18px;
}

.mat-focused .mat-form-field-label {
  /*change color of label*/
  color: $colorActiveState !important;
}

.mat-form-field-ripple {
  /*change color of underline when focused*/
  background-color: $colorActiveState !important;
}

.mat-option-text {
  color: black !important;
}

@media screen and (max-width: 1024px) {
  select.mat-input-element {
    font-size: 14px;
  }
}

.gray-input {
  .mat-form-field-infix {
    border: 1px solid #afafaf;
    background: #f0f0f0;
    padding-left: 0.4375em;
  }

  input.mat-input-element {
    height: 20px;
  }

  .mat-form-field-label {
    overflow: visible !important;
  }

  .mat-form-field-label-wrapper {
    transform: translateY(-12px) !important;
  }

  .mat-form-field-label {
    transform: translateY(-12px) !important;
    font-size: 14px !important;
    color: #585858 !important;
  }
  .mat-form-field {
    margin-top: 20px;
  }
  .input-text {
    border: 1px solid #afafaf !important;
    background: #f0f0f0 !important;
    padding: 0.4375em;
  }
}

.mat-select-disabled {
  .mat-select-arrow-wrapper {
    display: none !important;
  }
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: lightgray;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: $colorActiveState;
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: $colorActiveState;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: $colorActiveState;
}
.cdk-overlay-container {
  z-index: 9999 !important;
}

.mat-form-field-hide-placeholder .mat-select-placeholder {
  color: rgba(0,0,0,.42) !important;
  -webkit-text-fill-color: rgba(0,0,0,.42) !important;
}

.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-datepicker-toggle-default-icon, .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
  width: 1.5em !important;
}


.cart-content {
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  select.mat-input-element {
    padding-top: 0 !important;
    font-size: 16px !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0 !important;
  }

  .mat-form-field-infix {
    border-top: 0 !important;
  }
}

.favorite-content {
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  select.mat-input-element {
    padding-top: 0 !important;
    font-size: 16px !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0 !important;
  }

  .mat-form-field-infix {
    border-top: 0 !important;
  }
}





