/* =================================================
        Navigation.
================================================= */

.navigation {
  border-top: 1px solid $colorSilverChalice;

  @include tabletMax {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    width: 100%;
    height: calc(100% - 123px);
    z-index: 1;
    transition: transform $transitionDurationDefault ease;
    background-color: $colorGallery;
    overflow: hidden;
    margin-top: 123px;
    padding: 0;

    .header.open & {
      transform: none;
    }
  }

  @include tabletSmallMax {
    height: calc(100% - 103px);
    margin-top: 103px;
  }

  @include mobileLargeMax {
    height: calc(100% - 87px);
    margin-top: 87px;
  }
}

.navigation-inner {
  @include tabletMax {
    height: 100%;
    padding-bottom: 40px;
    overflow: auto;
  }

  @include mobileLargeMax {
    padding-bottom: 20px;
  }
}
