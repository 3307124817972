$further-link--spacing: 10px;

.further-link {
  display: flex;
  transition: color $transitionDurationDefault ease;

  &--inline {
    display: inline-flex;
  }

  &:hover {
    color: $colorActiveState;
  }
}

.further-link-before,
.further-link-after {
  display: block;
  transition: color $transitionDurationDefault, background $transitionDurationDefault,
    transform $transitionDurationDefault;
  transform: translateX(0);
}

.further-link-before {
  margin-right: $further-link--spacing;

  .further-link:hover & {
    transform: translateX(-4px);
  }
}

.further-link-after {
  margin-left: $further-link--spacing;

  .further-link:hover & {
    transform: translateX(4px);
  }
}
