.suggestions-title {
  &:not(:last-child) {
    margin-bottom: 18px;
  }

  @include mobileLargeMax {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.suggestions-items {
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}
