.info-list-item {
  display: flex;
  align-items: flex-start;
}

.info-list-item-icon {
  flex: 0 0 auto;
  margin-right: 6px;
}

.info-list-item-content {
  flex: 1 1 auto;
}
