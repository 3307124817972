@use 'sass:math';

$layout-fast-add-product-form--gutter--mobile: 12px;

.layout-fast-add-product {
  padding: 22px 16px;
  background-color: $colorGallery;
  margin-bottom: 96px;
}

.layout-fast-add-product-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 34px;

  @include tabletMax {
    display: block;
  }
}

.layout-fast-add-product-form {
  display: flex;
  align-items: flex-end;

  @include tabletMax {
    flex-wrap: wrap;
    margin-right: math.div($layout-fast-add-product-form--gutter--mobile, -2);
    margin-left: math.div($layout-fast-add-product-form--gutter--mobile, -2);
  }
}

.layout-fast-add-product-form-inner {
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-end;

  &:not(:first-child) {
    margin-left: 26px;

    @include tabletMax {
      margin-left: 0;
    }
  }

  @include mobileSmallMax {
    flex-wrap: wrap;
  }
}

.layout-fast-add-product-form-item {
  flex: 1 1 300px;

  @include tabletMax {
    padding: $layout-fast-add-product-form--gutter--mobile * 0.5;
  }

  &:not(:first-child) {
    margin-left: 26px;

    @include tabletMax {
      margin-left: 0;
    }
  }

  &--article-no {
    flex-basis: 472px;

    @include tabletMax {
      flex-basis: 140px;
    }

    @include mobileSmallMax {
      position: relative;
      width: 100%;
      flex-basis: 100%;
    }
  }

  &--amount {
    flex-basis: 404px;

    @include tabletMax {
      flex-basis: 110px;
    }
  }

  &--commission {
    flex-basis: 230px;

    @include tabletMax {
      flex-basis: 600px;
    }
  }

  &--submit {
    flex: 0 1 auto;

    @include tabletMax {
      flex: 1 1 100%;
    }
  }
}

.layout-fast-add-product-footer {
  margin-top: 30px;

  @include tabletMax {
    .inline-list {
      display: block;
    }
  }
}
