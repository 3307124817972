@use 'sass:math';

$layout-hero-tiles--gutter: 18px !default;

.layout-hero-tiles {
  margin: 0 math.div($layout-hero-tiles--gutter, -2);
}

.layout-hero-tiles-lower {
  display: flex;

  @include desktopMax {
    display: block;
  }
}

.layout-hero-tiles-lower-left {
  flex: 1 1 auto;
  width: 725px;

  @include desktopMax {
    width: auto;
  }
}

.layout-hero-tiles-lower-right {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 513px;

  @include desktopMax {
    width: auto;
  }
}

.layout-hero-tiles-lower-right-top,
.layout-hero-tiles-lower-right-bottom {
  flex: 1 0 auto;
}

.layout-hero-tiles-lower-right-top {
  height: 275px;

  @include desktopMax {
    height: auto;
  }
}

.layout-hero-tiles-lower-right-bottom {
  height: 206px;

  @include desktopMax {
    height: auto;
  }
}

.layout-hero-tiles-item {
  position: relative;
  padding: $layout-hero-tiles--gutter * 0.5;

  .swiper-button-next,
  .swiper-button-prev {
    opacity: 1;
    pointer-events: none;
    transition: opacity $transitionDurationDefault ease, color $transitionDurationDefault ease,
      background $transitionDurationDefault ease;
  }

  &:hover {
    .swiper-button-next,
    .swiper-button-prev {
      opacity: 0.84;
      pointer-events: auto;
    }

    .swiper-button-disabled {
      opacity: 0.7;
    }
  }
}
