.listing-sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.listing-sort-mobile {
  display: none;
  font-size: 24px;
  width: 26px;
  height: 26px;
  border: 1px solid $colorSilverChalice;
  color: $colorSilverChalice;
  transition: border-color $transitionDurationDefault ease, color $transitionDurationDefault ease;

  &:hover {
    color: $colorText;
    border-color: $colorText;
  }

  .touchevents & {
    color: $colorText;
    border-color: $colorText;
  }

  @include tabletMax {
    display: block;
  }
}

.listing-sort-page-size {
  @include tabletSmallMax {
    // Not needed anymore as the page will now be infiniteLoaded.
    display: none;
  }
}

.listing-sort-icons button {
  font-size: 24px;
  color: $colorSilverChalice;
  transition: color $transitionDurationDefault ease;

  &.active {
    color: $colorBlack;
  }

  &:hover {
    color: rgba($colorBlack, 0.6);
  }

  &:not(:last-child) {
    margin-right: 5px;
  }
}

.listing-sort * {
  max-width: 400px;
}
