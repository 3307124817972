.select-image {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: -5px;
}

.select-image-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: 34px;
  height: 34px;
  padding: 4px;
  margin-bottom: 5px;
  border: 2px solid transparent;
  transition: border-color $transitionDurationDefault ease;

  &:not(:last-child) {
    margin-right: 5px;
  }

  &:hover {
    border-color: rgba($colorSilverChalice, 0.5);
  }

  &.active {
    border-color: $colorSilverChalice;
  }

  img {
    max-width: 100%;
  }
}
