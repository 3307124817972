$masonry-grid-tile--gutter: 18px !default;

.masonry-grid-tile {
  padding: $masonry-grid-tile--gutter * 0.5;
  background-clip: padding-box;
  display: flex;

  .p-grid-item > & {
    padding: 0;
  }
}

.masonry-grid-tile-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: $colorBlack;
  background-color: $colorGallery;
  transition: color $transitionDurationDefault, background $transitionDurationDefault;

  .masonry-grid-tile:hover & {
    color: $colorWhite;
    background-color: $colorMineShaft;
  }
}

.masonry-grid-tile-image {
  flex-grow: 1;
  background-size: cover;
  background-position: 50% 50%;

  img {
    width: 100%;
    height: auto;
  }

  & + .masonry-grid-tile-caption {
    flex-grow: 0;
    margin: 0;
  }
}

.masonry-grid-tile-teaser,
.masonry-grid-tile-caption {
  margin: auto;
  padding: 20px 20px 10px;
}

.masonry-grid-tile-teaser {
  padding-bottom: 20px;

  .headline-third {
    margin-top: 4px;
    margin-bottom: 20px;
  }

  .btn {
    margin-top: 20px;
    background-color: $colorGallery;

    &:hover {
      color: $colorBrightText;
      background-color: $colorActiveState;
      border-color: $colorActiveState;
    }
  }
}
